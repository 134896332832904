// import { PageLayout } from "../../layouts";
import { CorporatesAndEventsProvider } from "../../context/CorporatesAndEvents";
import { PageLayout } from "../../layouts";
import CorporatesAndEventsHeader from "./CorporatesAndEventsHeader";
import CorporatesAndEventsModal from "./CorporatesAndEventsModal";
import CorporatesAndEventsTable from "./CorporatesAndEventsTable";

export const CorporatesAndEventsPage = () => {
  return (
    <CorporatesAndEventsProvider>
      <PageLayout Header={<CorporatesAndEventsHeader />}>
        <CorporatesAndEventsTable />
        <CorporatesAndEventsModal />
      </PageLayout>
    </CorporatesAndEventsProvider>
  );
};
