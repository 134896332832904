import { TDashboardView } from "../../interfaces";
import { ILineItemWithOrder, ISessionWithBookings } from "../../interfaces/Session";
import { useBookingSession } from "../../context/BookingSession";
import useSession from "./useSession";
import { useSession as useAuthSession } from "../../components/Session";
import { getDriverCount, getFinancialStatus, getSameSessionBookings, getStatusClass } from "./utilities";
import Notes from "./Notes";
import RaceReadyMsg from "./RaceReadyMsg";
import QrCode from "qrcode.react";
import { useDevice } from "../../hooks/useDevice";
import { useCallback, useMemo, useState } from "react";
import SessionVariantOptions from "./SessionVariantOptions";
import { useDriverRegistration } from "../../context/DriverRegistration";
import Name from "./Name";
import Occasion from "./Occasion";
import CheckInComponent from "./CheckIn";
import { Tooltip } from "@material-tailwind/react";
import { IFirebaseDraftOrder } from "../../../functions/src/interfaces/firebaseEntities";
import { ICustomer } from "shopify-api-node";
import DragHandle from "../../components/Shared/DragHandle";
import ConditionalWrapper from "../../components/Shared/ConditionalWrapper";

interface Props {
  dashboardView: TDashboardView;
  session: ISessionWithBookings;
  draftOrders?: IFirebaseDraftOrder[];
  isItemExchangeEnable: boolean;
  handleViewOrderClick: (booking: ILineItemWithOrder | IFirebaseDraftOrder) => void;
  handleViewRegistrationModalOpen: (booking: ILineItemWithOrder) => void;
  handleDragDrop: (from: ILineItemWithOrder, to: ISessionWithBookings) => void;
}
function Session({ session, dashboardView, draftOrders = [], isItemExchangeEnable, handleViewOrderClick, handleViewRegistrationModalOpen, handleDragDrop }: Props) {
  const { track: view, date: selectedDate, sessionWiseWetherData, refreshSessions } = useBookingSession();
  const { permissions } = useAuthSession();
  const { bookings: registrationBookings } = useDriverRegistration();

  const trackStaff = permissions?.roles?.includes("TRACK_STAFF");
  const hasCheckInFeatureAccess = permissions?.features?.includes("CHECKIN");
  const hasRrmFeatureAccess = permissions?.features?.includes("RRM");
  const isComplexView = useMemo(() => dashboardView === "COMPLEX_VIEW", [dashboardView]);
  const {
    RowClasses,
    staffCount,
    sessionTime,
    isBreakRace,
    filteredBookings,
    getBookingTime,
    getPaidPriceContent,
    sessionColor,
    rrmLog,
    // grmLogs,
    getRegisteredDriversCount,
    isRefundedOrReturnedBooking
  } = useSession({
    session,
    view,
    registrationBookings,
    isComplexView
  });

  const { isIPad } = useDevice();

  const [isHovering, setIsHovering] = useState(false);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    setIsHovering(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    setIsHovering(false);
    try {
      const data = JSON.parse(e.dataTransfer.getData('text/plain'));
      handleDragDrop(data.extraData.booking, session);
    } catch (error) {
      console.error('Error parsing drag data:', error);
    }
  }, [handleDragDrop, session]);

  return (
    <tr 
      key={session.id} 
      className={`${RowClasses} ${isItemExchangeEnable && isHovering ? 'bg-pink-100' : ''}`}
      onDragOver={isItemExchangeEnable ? handleDragOver : undefined}
      onDragLeave={isItemExchangeEnable ? handleDragLeave : undefined}
      onDrop={isItemExchangeEnable ? handleDrop : undefined}
    >
      {/* Barcode */}
      {!isIPad && isComplexView && (
        <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center justify-center flex-shrink-0">
            {/* <Barcode value={session.sku} format="CODE128" /> */}
            <QrCode
              value={session.sku}
              level="H"
              includeMargin
              fgColor={(session.bookings.map((booking) => getDriverCount(booking)).reduce((sum, val) => sum + val, 0) && (session.inventory_quantity === 0)) ? "#ff0000" : "#000"}
              bgColor="transparent"
            />
          </div>
        </td>
      )}

      {/* staff count */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span className="text-center">{staffCount}</span>
          </div>
        </div>
      </td>

      {/* Session/Time */}
      <td className={`pl-4 pr-2 py-3 max-w-0 whitespace-nowrap relative`}>
        <div className="flex items-center flex-shrink-0 ">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              <div className="flex items-center">
                <p>{sessionTime}</p>
                {(isComplexView && sessionWiseWetherData[session.sku] && sessionWiseWetherData[session.sku].weather?.length) && (
                  <div className="w-10 h-10">
                    <Tooltip content={sessionWiseWetherData[session.sku].weather[0].description}>
                      <img src={`https://openweathermap.org/img/wn/${sessionWiseWetherData[session.sku].weather[0].icon}@2x.png`} alt="wether icon"/>
                    </Tooltip>
                  </div>
                )}
              </div>
              <br />
              <SessionVariantOptions type={view} session={session} hasUpdateSessionPermission={!!!trackStaff} />
              <br />
            </span>
          </div>
        </div>
        <div className={`absolute w-2 top-0 bottom-0 left-0 ${sessionColor}`} />
      </td>

      {/* Price */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span>${session.price}</span>
          </div>
        </div>
      </td>

      {/* Inventory Quantity */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span className="text-center">{session.inventory_quantity}</span>
          </div>
        </div>
      </td>

      {/* Booking Name */}
      <td className="px-2 py-3 max-w-fit font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly">
            {isBreakRace && <b className="text-base">BREAK RACE</b>}
            {filteredBookings.map((booking) => (
              <div key={booking.id} className="flex items-center gap-1 transition-all duration-200 ease-in-out">
                <ConditionalWrapper condition={isItemExchangeEnable} Wrapper={<OrderExchangeHandleWrapper booking={booking} />}>
                  <Name customer={booking.order.customer} key={booking.id} hasMultipleBooking={getSameSessionBookings(session, booking).length > 1} refundedOrReturned={isRefundedOrReturnedBooking(booking)}/>
                </ConditionalWrapper>
              </div>
            ))}
            {isComplexView && draftOrders.map(draftOrder => <Name className="text-[#d32efe]" customer={draftOrder.customer as ICustomer} key={draftOrder.id} hasMultipleBooking={false} refundedOrReturned={false}/>)}
          </div>
        </div>
      </td>

      {/* Notes */}
      <td className="px-2 py-3 max-w-fit">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly">
            {filteredBookings.map((booking) => (
              <Notes view={view} booking={booking} key={booking.id} refreshSessions={refreshSessions} />
            ))}
            {isComplexView && draftOrders.map(draftOrder => (
              <div key={draftOrder.id} className={`border-b flex flex-row items-center text-[#d32efe]`}>
                <Notes view={view} booking={draftOrder} key={draftOrder.id} refreshSessions={refreshSessions} />
              </div>
            ))}
          </div>
        </div>
      </td>

      {/* Occasion */}
      <td className="px-2 py-3 max-w-fit">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly items-center">
            {filteredBookings.map((booking) => (
              <Occasion booking={booking} key={booking.id} />
            ))}
            {isComplexView && draftOrders.map((draftOrder) => (
              <div key={draftOrder.id} className={`border-b`}>
                <p>&#8203;</p>
              </div>
            ))}
          </div>
        </div>
      </td>

      {/* booking time */}
      {isComplexView && (
        <td className="px-2 py-3 max-w-fit font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center h-full">
            <div className="flex flex-col w-full h-full justify-evenly">
              {filteredBookings.map((booking: ILineItemWithOrder) => (
                <div key={booking.id} className={`py-2 border-b flex flex-row ${isRefundedOrReturnedBooking(booking) ? "line-through" : ""}`} style={{ width: "max-content" }}>
                  {getBookingTime(booking.order.created_at)}
                </div>
              ))}
              {isComplexView && draftOrders.map(draftOrder => (
                <div key={draftOrder.id} className={`py-2 border-b flex flex-row text-[#d32efe]`} style={{ width: "max-content" }}>
                  {getBookingTime(draftOrder.created_at)}
                </div>
              ))}
            </div>
          </div>
        </td>
      )}

      {/* paid price  */}
      {isComplexView && (
        <td className="px-2 py-3 max-w-fit font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center h-full">
            <div className="flex flex-col w-full h-full justify-evenly items-center">
              {filteredBookings.map((booking: ILineItemWithOrder) => (
                <div key={booking.id} className={`py-2 border-b flex flex-row ${isRefundedOrReturnedBooking(booking) ? "line-through" : ""}`} style={{ width: "max-content" }}>
                  {getPaidPriceContent(booking)}
                </div>
              ))}
              {isComplexView && draftOrders.map(draftOrder => (
                <div key={draftOrder.id} className={`py-2 border-b flex flex-row text-[#d32efe]`} style={{ width: "max-content" }}>
                  {/* price Paid will be the price per lineitem */}
                  {/* <p>{draftOrder.line_items.find(li => li.variant_id === session.id)?.price}</p> */}
                  <p>{getPaidPriceContent(draftOrder)}</p>
                </div>
              ))}
            </div>
          </div>
        </td>
      )}

      {/* No of drivers */}
      <td className="px-2 py-3 font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center space-x-2 h-full">
          <div className="flex flex-col text-center w-full h-full justify-evenly">
            {filteredBookings.map((booking: ILineItemWithOrder) => (
              <div className={`flex items-center justify-center py-2 border-b `} key={booking.id}>
                <div>{getDriverCount(booking)}</div>&nbsp;
              </div>
            ))}
            {isComplexView && draftOrders.map(draftOrder => (
              <div key={draftOrder.id} className={`flex items-center justify-center py-2 border-b text-[#d32efe]`}>
                <div>{draftOrder.line_items.find(li => li.variant_id === session.id)?.quantity}</div>
              </div>
            ))}
          </div>
        </div>
      </td>

      {/* Race ready send sms */}
      {(!trackStaff || hasRrmFeatureAccess) && (
        <td className="px-2 py-3 font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center h-full">
            <div className="flex flex-col w-full h-full justify-evenly">
              <RaceReadyMsg session={session} view={view} rrmLog={rrmLog} filteredBookings={filteredBookings} />
            </div>
          </div>
        </td>
      )}

      {/* Registration status */}
      <td className="px-2 py-3 font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly">
            {filteredBookings.map((booking: ILineItemWithOrder) => (
              <div key={booking.id} className={`flex justify-start items-center gap-2`}>
                <div className={getStatusClass(booking.order.financial_status)}>{getFinancialStatus(booking.order)}</div>
                {(!trackStaff || hasCheckInFeatureAccess) && <CheckInComponent booking={booking} selectedDate={selectedDate} getDriverCount={getDriverCount} />}

                <button
                  onClick={() => handleViewOrderClick(booking)}
                  className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-purple-500  hover:bg-purple-700 rounded-full"
                >
                  View Order
                </button>
                {/* {view === "VR" && <GoogleReviewMsg order={booking.order} grmLog={grmLogs[booking.order.id]} />} */}
                <button
                  onClick={() => handleViewRegistrationModalOpen(booking)}
                  className={`items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center rounded-full ${
                    getRegisteredDriversCount(booking) === 0
                      ? "bg-black"
                      : getRegisteredDriversCount(booking) >= getDriverCount(booking)
                      ? "bg-[#FF00FF]"
                      : "bg-sky-500  hover:bg-sky-700"
                  }`}
                >
                  {getRegisteredDriversCount(booking)}
                </button>
              </div>
            ))}
            {isComplexView && draftOrders.map(draftOrder => (
              <div key={draftOrder.id} className={`flex justify-start items-center gap-2 text-[#d32efe]`}>
                <button
                  onClick={() => handleViewOrderClick(draftOrder)}
                  className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-[#d32efe] hover:bg-[#b217dd] rounded-full"
                >
                  View Order
                </button>
              </div>
            ))}
          </div>
        </div>
      </td>
    </tr>
  );
}

interface OrderExchangeHandleWrapperProps {
  children?: React.ReactNode;
  booking: ILineItemWithOrder;
}

const OrderExchangeHandleWrapper: React.FC<OrderExchangeHandleWrapperProps> = ({ children, booking }) => {
  return (
    <DragHandle id={booking.id} extraData={{ booking }}>
      {children}
    </DragHandle>
  )
}

export default Session;
