import { ClockIcon } from "@heroicons/react/solid";
import { DateTime } from "luxon";
import { FC, useCallback, useEffect, useState } from "react";
import Label from "./Label";
import { isValidTimeRangeWithStartAndEnd, TTimeRange } from "../../utilities/dateUtils";

interface Props {
  required?: boolean;
  label?: string;
  onChange?: (val: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  isTimeStartAndEnd?: true;
}
const TimeInput: FC<Props> = ({ required = false, label, onChange, className = "", value: parentValue, disabled = false, placeholder, isTimeStartAndEnd }) => {
  const [selectedTime, setSelectedTime] = useState(() => {
    if (isTimeStartAndEnd) return "";
    return DateTime.local().toFormat("T");
  });
  const [error, setError] = useState("");

  const validate = useCallback(() => {
    if (isTimeStartAndEnd) {
      const [startedTime, endedTime] = selectedTime.split(" - ");
      const [startedTimeHour, startedTimeMinute] = startedTime.split(":");
      const [endedTimeHour, endedTimeMinute] = endedTime.split(":");
      const isTimeValid = (hour: string, minute: string): boolean => {
        return hour !== undefined && minute !== undefined && hour !== "" && minute !== "";
      };
      if (required && !selectedTime) {
        setError("This is a required field");
      } else if (!isTimeValid(startedTimeHour, startedTimeMinute) || !isTimeValid(endedTimeHour, endedTimeMinute)) {
        setError("Time range is incomplete (hh:mm - hh:mm)");
      } else if (!isValidTimeRangeWithStartAndEnd(`${startedTimeHour}:${startedTimeMinute} - ${endedTimeHour}:${endedTimeMinute}` as TTimeRange)) {
        setError("Time range is not in the correct format (hh:mm - hh:mm)");
      } else {
        setError("");
      }
    } else {
      const [hour, minute] = selectedTime.split(":");
      if (required && !selectedTime) {
        setError("This is required field");
      } else if (!(hour && minute && !isNaN(hour as any) && !isNaN(minute as any) && +hour <= 24 && +minute <= 60)) {
        setError("Time is not in correct formate (hh:mm)");
      } else {
        setError("");
      }
    }
  }, [selectedTime, required, isTimeStartAndEnd]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
      onChange && onChange(e.target.value, e);
    },
    [onChange]
  );

  useEffect(() => {
    if (parentValue !== undefined) {
      setSelectedTime(parentValue);
    }
  }, [parentValue]);

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {!!label && <Label showRequiredSymbol={required}>{label}</Label>}
      <div className="relative">
        <input
          type="text"
          className={`flex-1 p-3 rounded-lg border border-solid border-[#C2C0C3] text-[#393B41] text-xs not-italic font-medium leading-[normal] font-[Montserrat] w-full`}
          value={selectedTime}
          onChange={handleChange}
          onBlur={validate}
          disabled={disabled}
          placeholder={placeholder || ""}
        />
        <ClockIcon className="w-6 h-6 cursor-pointer text-[#9D62FE] absolute -translate-y-2/4 right-2.5 top-2/4" />
      </div>
      {error && <p className="text-red-600 text-xs font-bold leading-[normal] font-[Montserrat]">{error}</p>}
    </div>
  );
};

export default TimeInput;
