import { DateTime } from "luxon";

// Utility function to get the ordinal suffix
export const getOrdinalSuffix = (day: number): string => {
  if ([11, 12, 13].includes(day)) return "th";
  if (day % 10 === 1) return "st";
  if (day % 10 === 2) return "nd";
  if (day % 10 === 3) return "rd";
  return "th";
};

// Function to format date with ordinal suffix
export const formatDateWithOrdinalSuffix = (eventDate: string): string => {
  const luxonDate = DateTime.fromISO(eventDate);
  const day = luxonDate.day;
  const ordinalSuffix = getOrdinalSuffix(day);
  return luxonDate.toFormat(`d'${ordinalSuffix}' LLLL yyyy`);
};

export type TTimeRange = `${TTime} - ${TTime}`;
type TTime = `${number}:${number}`;

export function isValidTimeRangeWithStartAndEnd(timeRange: TTimeRange): boolean {
  const [startTime, endTime] = timeRange.split(" - ") as [TTime, TTime];

  // Validate individual times
  const isValidTime = (time: TTime) => {
    const [hour, minute] = time.split(":").map(Number);
    return !isNaN(hour) && !isNaN(minute) && hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59;
  };

  const isEndTimeAfterStartTime = (startTime: TTime, endTime: TTime): boolean => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    return endHour > startHour || (endHour === startHour && endMinute > startMinute);
  };

  return isValidTime(startTime) && isValidTime(endTime) && isEndTimeAfterStartTime(startTime, endTime);
}

export const clearTime = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};