import { Fragment, useCallback, useMemo, useState } from "react";
import { useBookingSession } from "../../context/BookingSession";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import { ISessionWithBookings } from "../../interfaces/Session";
import PriceRow from "./PriceRow";
import { usePricechanger } from "../../context/PriceChanger";
import { TMainTrackAgeFilters } from "../../interfaces";
import Calender from "../../components/Shared/Calender";
import ComparisonBookingSessionProvider from "../../context/ComparisonBookingSession";

function PriceTableContent() {
  const { loading, filteredSessions, track, ageFilter, product, changeAgeFilter } = useBookingSession();
  const { recommendedPrices, config, changeSelectedDate, selectedDateForCompareProduct } = usePricechanger();
  const [showComparison, setShowComparison] = useState<boolean>(true);
  const ageOptions = useMemo(() => (track === "MAIN_TRACK" ? Object.values(TMainTrackAgeFilters) : ["All Sessions"]), [track]);

  const sessions = useMemo(
    () =>
      filteredSessions.filter((session) => {
        let valid = true;
        if (track === "MAIN_TRACK") {
          if (ageFilter !== TMainTrackAgeFilters.ALL && !(session.option1 || "").toLowerCase().includes(ageFilter.toLowerCase())) {
            valid = false;
          }
        }
        return valid;
      }),
    [filteredSessions, ageFilter, track]
  );

  const handleDateChange = useCallback(
    (val: Date) => {
      changeSelectedDate(val, "compareProduct");
    },
    [changeSelectedDate]
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="dashboard-session-table-wrapper m-7 h-[calc(100%_-_3.5rem)] overflow-auto">
      <table className="min-w-full h-[1px]">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Checklist
            </th>
            <th className="session-filter-header p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
              <select
                className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={ageFilter}
                onChange={(e) => changeAgeFilter(e.target.value as TMainTrackAgeFilters)}
              >
                {ageOptions.map((opt) => {
                  return <option key={opt}>{opt}</option>;
                })}
              </select>
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Qty
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-[1111] text-center" colSpan={3} scope="col">
              <div className="flex items-center justify-between gap-2">
                <p>New Prices</p>
                <div className="flex items-center justify-center gap-2">
                  <div className="flex items-center gap-2">
                    <span className="text-black font-bold">Compare at:</span>
                    <Calender showCalendarIcon={false} onDateChange={handleDateChange} defaultValue={selectedDateForCompareProduct} />
                  </div>
                  <div className="flex items-center gap-2">
                    <span className=" text-black font-bold">Show Comparison:</span>
                    {showComparison ? (
                      <svg
                        width="48"
                        height="25"
                        viewBox="0 0 48 25"
                        fill="none"
                        className="cursor-pointer"
                        onClick={() => setShowComparison(!showComparison)}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="48" height="25" rx="12.5" fill="#2EBF3C" />
                        <circle cx="35.5" cy="12.5" r="10.5" fill="white" />
                      </svg>
                    ) : (
                      <svg
                        width="48"
                        height="25"
                        viewBox="0 0 48 25"
                        fill="none"
                        className="cursor-pointer"
                        onClick={() => setShowComparison(!showComparison)}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="48" height="25" rx="12.5" fill="#D9D9D9" />
                        <circle cx="12.5" cy="12.5" r="10.5" fill="white" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </th>
            {/* above th is taking space of total 3 cell */}
            {/* <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col" />
            <th className="bg-gray-100 sticky -top-1 z-10" scope="col"></th> */}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {sessions?.length !== 0 &&
            sessions.map((session: ISessionWithBookings, index: number) => (
              <Fragment key={session.id}>
                <PriceRow
                  index={index}
                  session={session}
                  recommendation={recommendedPrices.find((recommendedPrice) => recommendedPrice.VARIANT_ID === session.id)}
                  config={config}
                  product={product}
                  showComparison={showComparison}
                />
              </Fragment>
            ))}
          {sessions.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div className="text-lg text-gray-400 text-center h-screen mt-[20%]">No records to display</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function PriceTable() {
  const { track } = useBookingSession();
  const { selectedDateForCompareProduct } = usePricechanger();

  return (
    <ComparisonBookingSessionProvider date={selectedDateForCompareProduct} track={track}>
      <PriceTableContent />
    </ComparisonBookingSessionProvider>
  );
}

export default PriceTable;
