import { ChangeEventHandler, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Modal from "../../components/Shared/Modal";
import QrCode from "qrcode.react";
import { IOrder, IOrderLineItem } from "shopify-api-node";
import ModalSection from "../../components/Modal/ModalSection";
import { TDriverIndexResult, useDriverRegistration } from "../../context/DriverRegistration";
import LoadingSpinner from "../../components/Loading";
import { deepCopy, getAgeInYears, getDatetimeFromSku, getTrackFromSku, getTrackDisplayName, getLiveUsersRegistrationContent } from "../../utilities";
import { TBookingType, BOOKING_TYPES } from "../../interfaces";
import { IFirebaseBookingDocument, IRegistration } from "../../interfaces/driverRegistration";
import { DateTime } from "luxon";
import Button from "../../components/Shared/Button";
import { Combobox } from "@headlessui/react";
import AlgoliaSearch from "algoliasearch";
import { AlgoliaIndexes } from "../../constants";
import { ChevronDownIcon, ChevronUpIcon, EyeIcon, SearchIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { ILineItemWithOrder } from "../../interfaces/Session";
import TextInput from "../../components/Shared/TextInput";
import { Tooltip } from "@material-tailwind/react";
import { useOnlineRegistrationFormUser } from "../../hooks/useOnlineRegistrationFormUser";

interface ModalProps {
  show: boolean;
  handleClose: () => void;
  order: IOrder;
  view: TBookingType;
  selectedDate: Date;
  handleViewOrderClick: (booking: ILineItemWithOrder) => void;
  onActivityChange?: (activity: TBookingType) => void;
}

type TIsChanged = "ADDED" | "REMOVED" | boolean;

export default function DriverRegistrationModal(props: ModalProps) {
  const { order, show, handleClose, view, selectedDate, handleViewOrderClick, onActivityChange } = props;
  const { bookings, bookingsLoading, driversLoading, drivers, addToSession, addDriverToRace, clearSelfCheckIn } = useDriverRegistration();
  const qrCodeParent = useRef<HTMLDivElement | null>(null);
  const [added, setAdded] = useState<IRegistration[]>([]);
  const [removed, setRemoved] = useState<IRegistration[]>([]);
  const [qrCodeWidth, setQrCodeWidth] = useState(0);
  const [isAddedingToSession, setIsAddedingToSession] = useState(false);
  const tableRef = useRef<TRegistrationTableHandle>(null);
  const [selectedDrivers, setSelectedDrivers] = useState(0);
  const [addingDriverToRace, setAddingDriverToRace] = useState(false);
  const [isClearingSelfCheckIn, setIsClearingSelfCheckIn] = useState(false);
  const { count } = useOnlineRegistrationFormUser(order.id);

  const booking = useMemo(() => {
    const b = bookings?.[order?.id];
    if (b && b.drivers && b.drivers.length) {
      //sort drivers
      b.drivers.sort((a, b) => {
        if (drivers?.[a]?.first_name?.toLowerCase() < drivers?.[b]?.first_name?.toLowerCase()) return -1;
        if (drivers?.[a]?.first_name?.toLowerCase() > drivers?.[b]?.first_name?.toLowerCase()) return 1;
        return 0;
      });
    }
    return b;
  }, [order, bookings, drivers]);

  const RegistrationContent = useMemo(() => `https://registration.hyperkarting.com.au/bookings/${order.id}`, [order]);

  const RegistrationQRContent = useMemo(() => `https://registration.hyperkarting.com.au/bookings/${order.id}?registration_source=in_venue`, [order]);

  const orderId = useMemo(() => String(order.id), [order]);

  const selfCheckedInDriverIds = useMemo(() => (booking?.selfCheckIn || []).map((registration) => registration.driverId), [booking]);

  const updateChanges = useCallback((registration: IRegistration, add: boolean) => {
    if (add) {
      // Check if item exists in removed array
      const existsInRemoved = removed.find(
        data => data.sku === registration.sku && data.driverId === registration.driverId
      );
      
      if (existsInRemoved) {
        // Remove from removed array
        setRemoved(oldRemoved => 
          deepCopy(oldRemoved.filter(data => 
            !(data.sku === registration.sku && data.driverId === registration.driverId)
          ))
        );
      } else {
        // Add to added array if not already there
        const existsInAdded = added.find(
          data => data.sku === registration.sku && data.driverId === registration.driverId
        );
        if (!existsInAdded) {
          setAdded(oldState => [...oldState, registration]);
        }
      }
    } else {
      // Check if item exists in added array
      const existsInAdded = added.find(
        data => data.sku === registration.sku && data.driverId === registration.driverId
      );
      
      if (existsInAdded) {
        // Remove from added array
        setAdded(oldAdded => 
          oldAdded.filter(data => 
            !(data.sku === registration.sku && data.driverId === registration.driverId)
          )
        );
      } else {
        // Add to removed array if not already there
        const existsInRemoved = removed.find(
          data => data.sku === registration.sku && data.driverId === registration.driverId
        );
        if (!existsInRemoved) {
          setRemoved(oldState => 
            deepCopy([...oldState, registration])
          );
        }
      }
    }
  }, [added, removed]);

  const someThingChanged = useMemo(() => !!(added.length || removed.length), [added, removed]);

  const isAnythingChanged = useCallback(
    (firebaseId: string, sku: string) => {
      if (added.find((obj) => obj.sku === sku && obj.driverId === firebaseId)) {
        return "ADDED";
      }
      if (removed.find((obj) => obj.sku === sku && obj.driverId === firebaseId)) {
        return "REMOVED";
      }
      return false;
    },
    [added, removed]
  );

  const handleAddToSession = useCallback(async () => {
    try {
      setIsAddedingToSession(true);
      const data = await addToSession({
        added,
        removed,
        orderId: orderId,
      });
      if (data.success) {
        // if(added.length){
        //   console.log("Some are added")
        //   const noOfRegistration = (booking?.registrations || []).reduce((acc, r) => ({...acc, [r.sku]: (acc[r.sku] || 0) + 1}), {} as Record<string, number>);
        //   const skuWithFirstBooking = Array.from(new Set(added.map(r=>r.sku))).filter(sku => !noOfRegistration[sku])
        //   const trackConfig: { session_id: string, track_configuration_id: string }[] = [];
        //   console.log("noOfRegistration", noOfRegistration)
        //   console.log("skuWithFirstBooking", skuWithFirstBooking);
          

        //   skuWithFirstBooking.forEach(sku => {
        //     const track = getTrackFromSku(sku);
        //     const dt = getDatetimeFromSku(sku, track);
        //     if(dt){
        //       const scheduleSession = scheduleSessions.find(s => s.start_time_key.includes(dt.toFormat("yyyy-MM-dd HH:mm")));
        //       if(scheduleSession){
        //         trackConfig.push({ session_id: scheduleSession.uuid, track_configuration_id: "" })
        //       }
        //     }
        //   });
        //   console.log("skuWithFirstBooking", skuWithFirstBooking)
        //   console.log("trackConfig", trackConfig)

        //   if(trackConfig.length){
        //     const snapshot = await getDoc(doc(trackConfigRef, DateTime.fromJSDate(selectedDate, { zone: "Australia/Sydney" }).toFormat("yyyy-MM-dd")));
        //     const trackConfigurationId = snapshot?.data()?.selected_track_config || DefaultTrackConfig;
        //     console.log("trackConfigurationId", trackConfigurationId)
        //     trackConfig.forEach(t => {
        //       t.track_configuration_id = trackConfigurationId;
        //     });
        //     const token = await getToken();
        //     await axios.post(
        //       // "http://127.0.0.1:5001/hyper-karting/us-central1/sessionEditorV2",
        //       "https://us-central1-hyper-karting.cloudfunctions.net/sessionEditorV2",
        //       { trackConfig },
        //       {
        //         headers: {
        //           Authorization: `Bearer ${token}`,
        //         },
        //       }
        //     );
        //   }
        // }
        setAdded([]);
        setRemoved([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsAddedingToSession(false);
    }
  }, [addToSession, added, removed, orderId]);

  const handleSelectAll = useCallback(() => {
    tableRef.current?.handleAddToSession?.();
  }, []);

  const clearAllSelfCheckIn = useCallback(async () => {
    try {
      setIsClearingSelfCheckIn(true);
      await clearSelfCheckIn(orderId);
    } catch (error) {
      console.log("🚀 ~ file: DriverRegistrationModal.tsx:128 ~ clearAllSelfCheckIn ~ error:", error);
    } finally {
      setIsClearingSelfCheckIn(false);
    }
  }, [clearSelfCheckIn, orderId]);

  const handleAddDriverToBooking = useCallback(
    (driver: TDriverIndexResult | null) => {
      if (!driver) {
        return;
      }
      setAddingDriverToRace(true);
      addDriverToRace(driver.objectID, String(orderId))
        .then((data) => {
          console.log(data);
        })
        .catch(console.error)
        .finally(() => {
          setAddingDriverToRace(false);
        });
    },
    [orderId, addDriverToRace]
  );

  //TODO for today's races
  const lineItems = useMemo(
    () =>
      order.line_items
        .filter((li) => {
          //It should match the track
          const track = getTrackFromSku(li.sku);
          if (li.sku && track !== view) {
            return false;
          }

          // session should not br refunded
          const isRefunded = (order?.refunds ?? []).find((refund) =>
            (refund?.refund_line_items ?? []).find(
              (rli) => rli.line_item_id === li.id && rli.quantity === li.quantity //If full refunded then ignore it
            )
          );
          if (isRefunded) {
            return false;
          }

          //check date it should be same as selected date
          const dt = getDatetimeFromSku(li.sku, view);
          const dtSelected = DateTime.fromJSDate(selectedDate, {
            zone: "Australia/Sydney",
          });

          return dt?.isValid && dt.toFormat("dd/MM/yyyy") === dtSelected.toFormat("dd/MM/yyyy");
        })
        .sort((prev, next) => {
          const prevDt = getDatetimeFromSku(prev.sku, view);
          const nextDt = getDatetimeFromSku(next.sku, view);
          return prevDt!.toMillis() - nextDt!.toMillis();
        }),
    [order, view, selectedDate]
  );

  const noOfDriversRegistered = useMemo(() => {
    return Array.isArray(booking?.drivers) ? booking.drivers.length : 0;
  }, [booking]);

  const noOfRacesSelected = useMemo(() => new Set(added.map((registration) => registration.sku)).size, [added]);

  const showClearSelfCheckin = useMemo(() => {
    return (booking?.selfCheckIn || []).some((selfCheckIn) => {
      const registered = (booking.registrations || []).find(
        (registration) => registration.driverId === selfCheckIn.driverId && registration.orderId === selfCheckIn.orderId && registration.sku === selfCheckIn.sku
      );
      return !registered;
    });
  }, [booking]);

  useEffect(() => {
    let resizeObserver = new ResizeObserver(() => {
      const width = qrCodeParent.current?.getBoundingClientRect?.()?.width;

      if (qrCodeParent.current && width !== undefined) {
        setQrCodeWidth(width);
      }
    });

    if (qrCodeParent.current) {
      resizeObserver.observe(qrCodeParent.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Get unique activities from line items, including current view
  const availableActivities = useMemo(() => {
    const activities = Array.from(new Set(order.line_items
      .map(item => item.sku ? getTrackFromSku(item.sku) : null)
      .filter((track): track is TBookingType => track !== null)));
    
    // Sort based on the order in BOOKING_TYPES
    return activities.sort((a, b) => 
      BOOKING_TYPES.indexOf(a) - BOOKING_TYPES.indexOf(b)
    );
  }, [order.line_items]);

  const showMaxDriverByLineItemWarning = useMemo(()=>(lineItems.some(li => [...(booking?.registrations || []).filter(r => !removed.find(rem => rem.driverId === r.driverId && rem.sku === r.sku)), ...added].filter(r => r.sku === li.sku).length > (isNaN((li as any).current_quantity) ? li.quantity : (li as any).current_quantity))), [lineItems, booking, added, removed]);

  return (
    <Modal
      title={
        handleViewOrderClick ? (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <p>Registration Details</p>
              <Button
                variant="small"
                type="secondary"
                onClick={() => {
                  handleClose();
                  handleViewOrderClick({
                    ...order.line_items[0],
                    order,
                  });
                }}
              >
                Booking Details
              </Button>
            </div>
            <div className="flex items-center gap-2 mr-4">
              {availableActivities.map(activity => (
                <Button
                  key={activity}
                  variant="small"
                  type={activity === view ? "primary" : "secondary"}
                  onClick={() => activity !== view ? onActivityChange?.(activity) : undefined}
                >
                  {getTrackDisplayName(activity)}
                </Button>
              ))}
            </div>
          </div>
        ) : (
          "Registration Details"
        )
      }
      show={show}
      onClose={handleClose}
      className="max-w-[95vw] h-[95vh]"
    >
      <div className="flex justify-between p-5 gap-6 h-[calc(100%-65px)] overflow-hidden">
        <div className="w-[25%] flex flex-col gap-2 overflow-y-auto">
          <ModalSection title="QR Code">
            <div ref={qrCodeParent}>
              {RegistrationQRContent && (
                <>
                  <div className="flex justify-center">
                    <QrCode renderAs="svg" value={RegistrationQRContent} level="H" includeMargin size={qrCodeWidth * 0.7} />
                  </div>
                  <a href={RegistrationContent} target="_blank" className="break-words text-gray-800 not-italic font-medium text-xs leading-none" rel="noreferrer">
                    {RegistrationContent}
                  </a>
                </>
              )}
            </div>
          </ModalSection>
          <ModalSection title="Status" headerContent={count ? (
            <div className="flex items-center ml-2">
              <EyeIcon className="w-4" />
              <p className="text-xs leading-none ml-1">{getLiveUsersRegistrationContent(count)}</p>
            </div>
          ) : <></>}>
            <div className="text-gray-800 not-italic font-medium text-xs leading-none flex flex-col gap-2">
              <p>{noOfRacesSelected} Races Selected</p>
              <p>{selectedDrivers} Drivers Selected</p>
              <p>{noOfDriversRegistered} Drivers Registered</p>
            </div>
          </ModalSection>
        </div>
        <div className="w-[75%] overflow-auto">
          {bookingsLoading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <ModalSection
              title="Racers"
              headerContent={
                <div className="ml-auto flex justify-center items-center gap-2">
                  {showClearSelfCheckin && (
                    <Button variant="small" onClick={clearAllSelfCheckIn} type="primary" className="bg-[#ff00ff]" loading={isClearingSelfCheckIn}>
                      Clear Self Checkin
                    </Button>
                  )}
                  <Button variant="small" onClick={handleSelectAll} type="primary" className="bg-[#ff00ff]">
                    Select all time
                  </Button>
                </div>
              }
            >
              <div className="flex gap-4">
                <div className="flex-1">{addingDriverToRace ? <LoadingSpinner /> : <AddNewDriver handleAddDriverToBooking={handleAddDriverToBooking} />}</div>
              </div>
              <div className="flex flex-col gap-2 mt-2">
                {driversLoading ? (
                  addingDriverToRace ? (
                    <></>
                  ) : (
                    <LoadingSpinner />
                  )
                ) : (
                  <RegistrationTable
                    lineItems={lineItems}
                    view={view}
                    booking={booking}
                    orderId={orderId}
                    isAnythingChanged={isAnythingChanged}
                    updateChanges={updateChanges}
                    ref={tableRef}
                    updateSelectedDriverCount={setSelectedDrivers}
                    selfCheckedInDriverIds={selfCheckedInDriverIds}
                    showMaxDriverByLineItemWarning={showMaxDriverByLineItemWarning}
                  />
                )}
              </div>
            </ModalSection>
          )}
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <div
          className="cursor-pointer inline-flex justify-center rounded-full border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-600"
          onClick={handleClose}
        >
          Back
        </div>
        <button
          className={`inline-flex justify-center rounded-full border border-transparent  px-4 py-2 text-sm font-medium text-white  ${
            someThingChanged || isAddedingToSession ? "cursor-pointer bg-purple-500 hover:bg-purple-600" : "bg-purple-300 pointer-events-none"
          }`}
          disabled={!someThingChanged || isAddedingToSession}
          onClick={handleAddToSession}
        >
          {isAddedingToSession ? "..." : (removed.length ? "Save Changes" : "Add to Session")}
        </button>
      </div>
    </Modal>
  );
}

interface IRegistrationTableProps {
  lineItems: IOrderLineItem[];
  view: TBookingType;
  booking: IFirebaseBookingDocument;
  orderId: string;
  selfCheckedInDriverIds: string[];
  showMaxDriverByLineItemWarning: boolean;
  isAnythingChanged: (firebaseId: string, sku: string) => TIsChanged;
  updateChanges: (registration: IRegistration, add: boolean) => void;
  updateSelectedDriverCount: (count: number) => void;
}
type TRegistrationTableHandle = {
  isAllTimeAdded: boolean;
  handleAddToSession: () => void;
};

const RegistrationTable = forwardRef<TRegistrationTableHandle, IRegistrationTableProps>(
  ({ lineItems, view, booking, orderId, isAnythingChanged, updateChanges, updateSelectedDriverCount, selfCheckedInDriverIds, showMaxDriverByLineItemWarning }, ref) => {
    const rows = useRef<Array<TDriverRowHandle | null>>([]);
    const [editableFirebaseIds, setEditableFirebaseIds] = useState<string[]>([]);
    const [searchText, setSearchText] = useState("");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

    const { drivers } = useDriverRegistration();

    const toggleSortDirection = useCallback(() => {
      setSortDirection((oldDirection) => (oldDirection === "asc" ? "desc" : "asc"));
    }, []);

    const handleChangeEditable = useCallback((firebaseId: string, val: boolean) => {
      if (val) {
        setEditableFirebaseIds((oldState) => [...oldState, firebaseId]);
      } else {
        setEditableFirebaseIds((oldState) => oldState.filter((id) => id !== firebaseId));
      }
    }, []);

    const handleAddToSession = useCallback(() => {
      setEditableFirebaseIds(booking.drivers);
      rows.current.forEach((row) => {
        const firebaseId = row?.firebaseId;
        if (firebaseId) {
          row.selectAll();
        }
      });
    }, [booking]);

    const isAllTimeAdded = useMemo(() => {
      let flag = true;
      for (let row of rows.current) {
        if (!row) {
          continue;
        }
        const firebaseId = row?.firebaseId;
        if (firebaseId && editableFirebaseIds.includes(firebaseId)) {
          if (row.skus.length !== lineItems.length) {
            flag = false;
            break;
          }
        }
      }
      return flag;
    }, [editableFirebaseIds, lineItems]);

    const isAllDriversSelected = useMemo(() => booking?.drivers?.length === editableFirebaseIds.length, [editableFirebaseIds, booking]);

    const handleSelectAllDriverChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (e.target.checked) {
          setEditableFirebaseIds(booking.drivers);
        } else {
          setEditableFirebaseIds([]);
          rows.current.forEach((row) => {
            if (row) {
              row.unCheckDriverRow();
            }
          });
        }
      },
      [booking]
    );

    const filteredDrivers = useMemo(
      () =>
        (booking?.drivers || [])
          .filter((id) => {
            const driver = drivers[id];
            if (!driver) {
              return false;
            }
            return `${driver.first_name} ${driver.last_name} ${driver.email} ${driver.phone_number}`.toLowerCase().includes(searchText.toLowerCase());
          })
          .sort((a, b) => {
            const driverA = drivers[a];
            const driverB = drivers[b];
            if (!driverA || !driverB) {
              return 0;
            }
            const sort = sortDirection === "asc" ? 1 : -1;
            return driverA.first_name.localeCompare(driverB.first_name) * sort;
          }),
      [booking, searchText, drivers, sortDirection]
    );

    useImperativeHandle(ref, () => ({
      handleAddToSession,
      isAllTimeAdded,
    }));

    useEffect(() => {
      updateSelectedDriverCount((booking?.drivers || []).reduce((acc, id) => acc + (editableFirebaseIds.includes(id) ? 1 : 0), 0));
    }, [booking, editableFirebaseIds, updateSelectedDriverCount]);

    useEffect(() => {
      //make driver row editable if self checkin is not registered
      const toBeEditable: string[] = [];
      (booking?.selfCheckIn || []).forEach((selfCheckIn) => {
        const registered = (booking.registrations || []).find(
          (registration) => registration.driverId === selfCheckIn.driverId && registration.orderId === selfCheckIn.orderId && registration.sku === selfCheckIn.sku
        );
        if (!registered) {
          toBeEditable.push(selfCheckIn.driverId);
        }
      });
      setEditableFirebaseIds(toBeEditable);
    }, [selfCheckedInDriverIds, booking]);

    const totalSessions: number = useMemo(() => {
      let totalQuantity = 0;
      lineItems.forEach((li) => {
        totalQuantity += (isNaN((li as any).current_quantity) ? li.quantity : (li as any).current_quantity);
      });
      return totalQuantity;
    }, [lineItems]);

    const showMaxDriverWarning = useMemo(()=>(filteredDrivers.length > totalSessions), [filteredDrivers, totalSessions]);

    if (!booking?.drivers?.length) {
      return <div>No registration found</div>;
    }

    return (
      <table className="min-w-full h-[1px] border-separate border-spacing-y-2">
        <thead>
          <tr>
            <th className="text-gray-800 not-italic font-medium font-[montserrat] text-xs leading-10 flex gap-2 pl-2 items-center">
              <input type="checkbox" className="accent-[#7886FF] w-4" value="Editable" onChange={handleSelectAllDriverChange} checked={isAllDriversSelected} />
              <div onClick={toggleSortDirection} className="cursor-pointer">
                Name
              </div>
              <div className="cursor-pointer" onClick={toggleSortDirection}>
                {sortDirection === "asc" ? <ChevronUpIcon className="w-4" /> : <ChevronDownIcon className="w-4" />}
              </div>
              <TextInput className="font-normal text-sm pl-2 px-2.5 py-1 flex-grow-0" placeholder="Type here..." value={searchText} onChange={setSearchText} />
            </th>
            {lineItems.map((li) => (
              <th key={li.id} className="text-gray-800 not-italic font-medium font-[montserrat] text-xs leading-10 text-center leading-[1.4]">
                <div className="mx-2">{getDatetimeFromSku(li.sku, view)?.toFormat("hh:mma")}</div>
                <div className="text-[red] mx-2">({li.quantity})</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredDrivers.map((firebaseId, index) => (
            <DriverRow
              firebaseId={firebaseId}
              index={index + 1}
              key={firebaseId}
              lineItems={lineItems}
              orderId={orderId}
              registrations={booking.registrations || []}
              selfCheckIn={booking.selfCheckIn || []}
              editable={editableFirebaseIds.includes(firebaseId)}
              handleChangeEditable={handleChangeEditable}
              isAnythingChanged={isAnythingChanged}
              updateChanges={updateChanges}
              view={view}
              ref={(el: any) => (rows.current[index] = el)}
            />
          ))}
          <tr>
            <td colSpan={5}>
              <div className="flex justify-between">
                {showMaxDriverWarning && (
                  <p className="text-[red] not-italic font-medium font-[montserrat] text-sm">Maximum of {totalSessions} Drivers expected.</p>
                )}
                {showMaxDriverByLineItemWarning && (
                  <p className="text-[red] not-italic font-medium font-[montserrat] text-sm">More drivers are about to be added to the session than the number of seats sold.</p>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
);

interface IDriverRowProps {
  lineItems: IOrderLineItem[];
  firebaseId: string;
  orderId: string;
  index: number;
  registrations: IRegistration[];
  selfCheckIn: IRegistration[];
  editable: boolean;
  view: TBookingType;
  handleChangeEditable: (firebaseId: string, val: boolean) => void;
  isAnythingChanged: (firebaseId: string, sku: string) => TIsChanged;
  updateChanges: (registration: IRegistration, add: boolean) => void;
}
type TDriverRowHandle = {
  firebaseId: string;
  selectAll: () => void;
  skus: string[];
  unCheckDriverRow: () => void;
};

const DriverRow = forwardRef<TDriverRowHandle, IDriverRowProps>(
  ({ firebaseId, index, lineItems, orderId, registrations, selfCheckIn, editable, handleChangeEditable, isAnythingChanged, updateChanges, view }, ref) => {
    const [isDeletingDriver, setIsDeletingDriver] = useState(false);
    const sessionsRef = useRef<Array<HTMLInputElement | null>>([]);
    const { drivers, removeDriverFromRace } = useDriverRegistration();

    const driverData = useMemo(() => drivers[firebaseId], [drivers, firebaseId]);

    const isBirthday = useMemo(() => {
      if (!driverData?.dob || !lineItems[0]?.sku) return false;
      const dob = DateTime.fromFormat(driverData.dob, "yyyy-MM-dd");
      const sessionDate = getDatetimeFromSku(lineItems[0].sku, view);
      return dob.isValid && sessionDate?.isValid && dob.toFormat("MM-dd") === sessionDate.toFormat("MM-dd");
    }, [driverData, lineItems, view]);

    const isDriverAddedToAnySession = useMemo(
      () => registrations.find((registration) => registration.orderId === orderId && registration.driverId === firebaseId),
      [registrations, orderId, firebaseId]
    );

    const unCheckDriverRow = useCallback(() => {
      const checkboxes = sessionsRef.current.filter((ele) => ele);
      checkboxes.forEach((ele) => {
        const sku = ele?.dataset?.value;
        if (!sku) return;

        const change = isAnythingChanged(firebaseId, sku);
        const existingVal = ele.checked;

        if ((change === "ADDED" && existingVal) || (change === "REMOVED" && !existingVal)) {
          ele.checked = !existingVal;
          updateChanges(
            {
              driverId: firebaseId,
              orderId,
              sku,
              raceType: view,
            },
            ele.checked
          );
        }
      });
    }, [firebaseId, view, orderId, isAnythingChanged, updateChanges]);

    const hadleEditableChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        handleChangeEditable(firebaseId, e.target.checked);
        if (!e.target.checked) {
          unCheckDriverRow();
        }
      },
      [firebaseId, handleChangeEditable, unCheckDriverRow]
    );

    const onChangeCheckbox: (lineItem: IOrderLineItem) => React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (li) => (e) => {
        // Remove preventDefault to allow default checkbox behavior
        const isChecked = e.target.checked;

        updateChanges(
          {
            driverId: firebaseId,
            orderId: orderId,
            sku: li.sku,
            raceType: view,
          },
          isChecked
        );
      },
      [updateChanges, firebaseId, orderId, view]
    );

    const selectAll = useCallback(() => {
      const checkboxes = sessionsRef.current.filter((ele) => ele);

      checkboxes.forEach((ele) => {
        const sku = ele?.dataset?.value;
        if (!sku) return;

        const change = isAnythingChanged(firebaseId, sku);
        const existingVal = ele.checked;

        if (!existingVal && change !== "ADDED") {
          ele.checked = true;
          updateChanges(
            {
              driverId: firebaseId,
              orderId,
              sku,
              raceType: view,
            },
            true
          );
        }
      });
    }, [firebaseId, isAnythingChanged, updateChanges, orderId, view]);

    const handleRemoveDriverFromBooking = useCallback(async () => {
      try {
        setIsDeletingDriver(true);
        const data = await removeDriverFromRace(orderId, firebaseId);
        if (data.success) {
          alert("Driver removed successfully.");
        }
      } catch (error) {
        console.log("error", error);
        alert(error);
      } finally {
        setIsDeletingDriver(false);
      }
    }, [firebaseId, orderId, removeDriverFromRace]);

    useEffect(() => {
      const checkboxes = sessionsRef.current.filter((ele) => ele);

      checkboxes.forEach((ele) => {
        const sku = ele?.dataset?.value;
        if(!sku 
          || registrations.find((e) => e.driverId === firebaseId && e.sku === sku) // check if driver is already registered for this sku
          || !selfCheckIn.find((e) => e.sku === sku && e.driverId === firebaseId && e.orderId === orderId) // check if driver is self checked in for this sku
        ) {
          return;
        }

        ele.checked = true;
        // checking checkbox for self checked in driver
        updateChanges(
          {
            driverId: firebaseId,
            orderId,
            sku,
            raceType: view,
          },
          true
        );
      });
    }, [selfCheckIn, firebaseId, orderId, view, registrations, updateChanges]);

    const age = useMemo(() => {
      const dt = DateTime.fromFormat(driverData?.dob ?? "", "yyyy-MM-dd");
      return dt.isValid ? getAgeInYears(dt) + " Year Old" : null;
    }, [driverData]);

    useImperativeHandle(ref, () => ({
      selectAll,
      firebaseId,
      skus: sessionsRef.current
        .map((ele) => ele?.dataset?.value)
        .filter((ele) => ele)
        .map((ele) => String(ele)),
      unCheckDriverRow,
    }));

    if (!driverData) {
      return <tr className="bg-[#F3F4F7]"></tr>;
    }

    return (
      <tr className="bg-[#F3F4F7]" data-racefacer-uuid={driverData.racefacer_uuid} data-driver-id={firebaseId}>
        <td className="w-[100%] bg-white p-0">
          <div className="text-gray-700 not-italic font-medium font-[montserrat] text-xs leading-10 bg-[#F3F4F7] rounded-md px-2 mr-2 flex gap-2 capitalize items-center">
            <input type="checkbox" className="accent-[#7886FF] w-4" value="Editable" onChange={hadleEditableChange} checked={editable} />
            <a href={`http://192.168.43.100/en/administration/clients/clients?uuid=${driverData.racefacer_uuid}`} rel="noreferrer" target="_blank" className="underline">
              <b>#{index}</b> {driverData.first_name} {driverData.last_name}
            </a>
            {isBirthday && (
              <Tooltip content="Birthday!">
                <span role="img" aria-label="birthday" className="text-lg">
                  🎈
                </span>
              </Tooltip>
            )}
            {age && <p className="text-xs text-gray-400 flex-1 text-right">{age}</p>}
            {isDeletingDriver ? (
              <div>Deleting...</div>
            ) : !isDriverAddedToAnySession ? (
              <TrashIcon className="w-4 ml-auto text-red-400 hover:text-red-600 cursor-pointer" onClick={handleRemoveDriverFromBooking} />
            ) : (
              <></>
            )}
          </div>
        </td>
        {lineItems.map((li, index) => (
          <td key={li.id} className="text-center bg-white p-0">
            <div className={`h-full flex justify-center bg-[#F3F4F7] ${index === 0 ? "rounded-l-md" : index === lineItems.length - 1 ? "rounded-r-md" : ""}`}>
              <AddedToSessionCheckbox
                value={li.sku}
                onChange={onChangeCheckbox(li)}
                disabled={!editable}
                ref={(el) => (sessionsRef.current[index] = el)}
                defaultChecked={!!registrations.find((registration) => registration.driverId === firebaseId && registration.sku === li.sku && registration.orderId === orderId)}
              />
            </div>
          </td>
        ))}
      </tr>
    );
  }
);

interface IAddedToSessionCheckboxProps {
  defaultChecked: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

const AddedToSessionCheckbox = forwardRef<HTMLInputElement, IAddedToSessionCheckboxProps>(({ value, onChange, defaultChecked, disabled }: IAddedToSessionCheckboxProps, ref) => {
  return (
    <input type="checkbox" className="accent-[#7886FF] w-4" value={value} onChange={onChange} defaultChecked={defaultChecked} disabled={disabled} ref={ref} data-value={value} />
  );
});

const AddNewDriver = ({ handleAddDriverToBooking }: { handleAddDriverToBooking: (driver: TDriverIndexResult | null) => void }) => {
  const [query, setQuery] = useState("");
  const [drivers, setDrivers] = useState<TDriverIndexResult[]>([]);
  const [loading, setLoading] = useState(false);
  const debounceSearchTimeoutRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const client = AlgoliaSearch(process.env.REACT_APP_ALGOLIA_APP_ID as string, process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string);
  const index = client.initIndex(AlgoliaIndexes.Drivers);

  const searchDriver: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setLoading(true);
      const q = event.target.value;
      setQuery(q);
      if (debounceSearchTimeoutRef.current) {
        clearTimeout(debounceSearchTimeoutRef.current);
        debounceSearchTimeoutRef.current = null;
      }
      debounceSearchTimeoutRef.current = setTimeout(() => {
        index
          .search(q)
          .then(({ hits }) => {
            setDrivers(hits as TDriverIndexResult[]);
          })
          .catch(console.error)
          .finally(() => {
            setLoading(false);
          });
      }, 300);
    },
    [index]
  );

  const handleChange = useCallback(
    (driver: TDriverIndexResult) => {
      handleAddDriverToBooking(driver);
    },
    [handleAddDriverToBooking]
  );

  const handleClearSearch = useCallback(() => {
    setQuery("");
    setDrivers([]);
  }, []);

  return (
    <Combobox onChange={handleChange}>
      <div className="relative w-full flex items-center">
        <Combobox.Input
          onChange={searchDriver}
          className="block w-full pl-2 text-gray-900 border border-solid border-gray-300 rounded-full bg-gray-100 font-normal text-sm px-2.5 py-1"
          placeholder="Search..."
          displayValue={(driver: TDriverIndexResult) => (driver ? `${driver?.first_name} ${driver?.last_name} (${driver.email}, ${driver.phone_number})` : "")}
        />
        {query ? (
          <div className="absolute right-2.5 cursor-pointer text-[#200E32]" onClick={handleClearSearch}>
            <XIcon className="w-6" />
          </div>
        ) : (
          <div className="absolute right-2.5 text-[#200E32] pointer-events-none">
            <SearchIcon className="w-6" />
          </div>
        )}
      </div>
      <Combobox.Options className="bg-gray-100 rounded-lg p-2 max-h-[30vh] overflow-auto">
        {loading && <LoadingSpinner />}
        {drivers.map((driver) => (
          <div className="flex items-center gap-1">
            <a href={`http://192.168.43.100/en/administration/clients/clients?uuid=${driver.racefacer_uuid}`} rel="noreferrer" target="_blank" className="underline">
              <EyeIcon className="w-5 text-gray-600" />
            </a>

            <Combobox.Option key={driver.objectID} value={driver} className="text-sm py-1 cursor-pointer hover:bg-gray-300">
              {({ active }) => (
                <div className={`flex gap-2 justify-between ${active ? "bg-gray-300" : ""}`}>
                  <span>
                    {driver.first_name} {driver.last_name}
                  </span>
                  <span className="text-xs text-gray-400">
                    {driver.email} ({driver.phone_number})
                  </span>
                </div>
              )}
            </Combobox.Option>
          </div>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};
