export const VR_PRICE_OPTIONS = [19, 24, 29, 34, 39];
export const MINI_TRACK_PRICE_OPTIONS = [14, 19, 24, 29];
export const MAIN_TRACK_PRICE_OPTIONS = [19, 24, 29, 34, 39, 44, 49, 54, 59];
export const PRICING_COLORS = {
    "14": "#2d7a00",
    "19": "#2d7a00",
    "24": "#2d7a00",
    "29": "#999900",
    "34": "#999900",
    "39": "#cc8b00",
    "44": "#cc8b00",
    "49": "#990099",
    "54": "#990099",
    "59": "#990000",
}
