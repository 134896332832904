import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ILineItemWithOrder, ISessionWithBookings } from "../interfaces/Session";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useFirebase } from "../components/Firebase";
import { IOrder, IProduct } from "shopify-api-node";
import { HIDDEN_TIMES } from "../constants/Dashboard";
import { TBookingType } from "../interfaces";
import { getProductHandle } from "../utilities";

export interface IComparisonBookingSessionContext {
  loading: boolean;
  product?: IProduct;
  filteredSessions?: ISessionWithBookings[];
}

const ComparisonBookingSessionContext = React.createContext<IComparisonBookingSessionContext>({
  loading: true,
  product: undefined,
  filteredSessions: [],
});

interface IProps {
  date: Date;
  track: TBookingType;
  children: ReactNode;
}

const ComparisonBookingSessionProvider: FC<IProps> = ({ children, date, track }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<IProduct>();
  const productHandle = useMemo(() => getProductHandle(date, track), [date, track]);
  const [filteredSessions, setFilteredSessions] = useState([] as ISessionWithBookings[]);
  const [originalSessions, setOriginalSessions] = useState([] as ISessionWithBookings[]);

  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const productRef = useMemo(() => collection(db, "products"), [db]);
  const orderRef = useMemo(() => collection(db, "orders"), [db]);

  const mapOrdersAndVariants = useCallback(async (variants: any[], orders: IOrder[]) => {
    let lineItemWithOrder = [] as ILineItemWithOrder[];

    orders.forEach((order) => {
      order.line_items.forEach((lineItem) => {
        lineItemWithOrder.push({
          order,
          ...lineItem,
        });
      });
    });
    let newSessions = await Promise.all(
      variants.map(async (variant) => {
        const { id } = variant;
        let bookings = lineItemWithOrder.filter((li) => li.variant_id === id);

        return {
          ...variant,
          bookings,
        };
      })
    );
    setOriginalSessions(newSessions);
    setLoading(false);
  }, []);

  const withoutRefunds = useMemo(() => {
    // ** Error in this code, it's filtering out the entire session, instead of just the refunded race inside the session ** //

    // const withoutRefunds = newSessions.filter(x => {
    //   const refunds = x.bookings.filter(y => y.order.financial_status === 'refunded');
    //   return refunds.length === 0
    // })
    return originalSessions.map((session) => {
      const bookings = session.bookings.filter(
        (booking) =>
          !booking.order.refunds
            .map((refund) => refund.refund_line_items)
            .flat()
            .find((e) => e.line_item_id === booking.id && e.quantity === booking.quantity)
      );

      return { ...session, bookings };
    });
  }, [originalSessions]);

  const loadSessions = useCallback(() => {
    setLoading(true);
    console.log("Fetching comparison data for:", {
      date: date.toISOString(),
      track,
      productHandle,
    });

    const prodQuery = query(productRef, where("handle", "==", productHandle));
    getDocs(prodQuery)
      .then((qs) => {
        if (qs.empty) {
          console.log("No comparison product found for:", productHandle);
          setLoading(false);
          return;
        }
        qs.forEach((doc) => {
          const productData = doc.data() as IProduct;
          console.log("Found comparison product:", {
            handle: productData.handle,
            title: productData.title,
            variantCount: productData.variants.length,
          });

          const { variants, id } = productData;
          setProduct(productData);

          let filteredVariants = track === "MAIN_TRACK" ? variants.filter((vari) => !vari.option3?.includes("Time") && !HIDDEN_TIMES.includes(vari.option3!)) : variants;

          const orderQuery = query(orderRef, where("product_ids", "array-contains", id));
          getDocs(orderQuery)
            .then((qs) => {
              let orders = [] as IOrder[];
              qs.forEach((doc) => {
                orders.push(doc.data() as IOrder);
              });
              console.log("Found orders count:", orders.length);
              mapOrdersAndVariants(filteredVariants, orders);
            })
            .catch((error) => {
              console.error("Error fetching comparison orders:", error);
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        console.error("Error fetching comparison product:", error);
        setLoading(false);
      });
  }, [productHandle, orderRef, productRef, track, mapOrdersAndVariants, date]);

  useEffect(() => {
    loadSessions();
  }, [loadSessions]);

  //Filter the sessions
  useEffect(() => {
    const newSessions = structuredClone(withoutRefunds) as ISessionWithBookings[];
    setFilteredSessions(
      newSessions.filter((session) => {
        return session.bookings;
      })
    );
  }, [date, withoutRefunds]);

  return (
    <ComparisonBookingSessionContext.Provider
      value={{
        loading,
        product,
        filteredSessions,
      }}
    >
      {children}
    </ComparisonBookingSessionContext.Provider>
  );
};

export default ComparisonBookingSessionProvider;

export function useComparisonBookingSession() {
  return useContext(ComparisonBookingSessionContext);
}
