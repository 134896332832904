import { IRacefacerTrack } from "../interfaces/racefacer";

export { ROUTES } from "./routes";

export const AlgoliaIndexes = {
  SmsLogs: "sms_logs",
  Drivers: "drivers",
};

export const TrackConfigurations: IRacefacerTrack[] = [
    {
        "id": 19,
        "name": "14/05/2024 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "613cfdaa-94cd-4ec3-a86f-11222d87f66f"
    },
    {
        "id": 20,
        "name": "14/05/2024 Anti-clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "61ab7e64-2943-4a30-b5a0-52121f5580e5"
    },
    {
        "id": 21,
        "name": "27/05/2024 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "db3910ba-594d-4cea-b9a2-3ff9535925c6"
    },
    {
        "id": 22,
        "name": "27/05/2024 Anti-clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "169cd04f-2136-4e7c-b9b6-68794a05ba22"
    },
    {
        "id": 23,
        "name": "19/08/2024 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "ba187e64-220a-4b3b-b09d-535fe8f00d1c"
    },
    {
        "id": 24,
        "name": "19/08/2024 Anti-clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "1b0502f1-dd1c-48e2-ad73-c3865ba65800"
    },
    {
        "id": 26,
        "name": "21/10/2024 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "af15ba8f-56da-4d3f-969e-53d8a8831391"
    },
    {
        "id": 27,
        "name": "21/10/2024 Anti-Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "1c68b37e-6fe0-4ddb-831e-2169bc63ca29"
    },
    {
        "id": 28,
        "name": "28/10/2024 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "6ebc13c8-3f50-430b-9e3c-e05b4794d47d"
    },
    {
        "id": 29,
        "name": "28/10/2024 Anti-Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "0bd8de88-0644-4747-8a74-dccacde55ea8"
    },
    {
        "id": 31,
        "name": "4/11/2024 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "be06c9a1-9231-4e4f-98fc-4ef0342949ae"
    },
    {
        "id": 32,
        "name": "11/11/2024 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "7b785aac-ce95-46e0-bb3d-aac8a318b30d"
    },
    {
        "id": 33,
        "name": "11/03/2025 Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "a33e6a5e-4a4c-4fe3-bb3b-053ba355901b"
    },
    {
        "id": 34,
        "name": "11/03/2025 Anti-Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "66c06b39-4cba-4cf9-9b45-27bf53e57567"
    },
    {
        "id": 35,
        "name": "11/03/2025 Shortened Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "e3bccc92-48c4-41dc-9b15-de4f60bfcf15"
    },
    {
        "id": 36,
        "name": "11/03/2025 Shortened Anti-Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "265d8aed-5364-4ad1-b231-2876ca916daf"
    },
    {
        "id": 37,
        "name": "Monza 1, Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 450,
        "is_active": 0,
        "uuid": "7ce31d9c-9a7e-4ff3-a3aa-bb7098a74ee9"
    },
    {
        "id": 38,
        "name": "Monza 1, Anti-Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 450,
        "is_active": 0,
        "uuid": "45fc3c62-72f9-4ce4-83dc-c0356dfcbd1c"
    },
    {
        "id": 39,
        "name": "Silverstone 1, Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 450,
        "is_active": 0,
        "uuid": "6d83def4-494d-4702-bd70-aa7584735897"
    },
    {
        "id": 40,
        "name": "Silverstone 1, Anti-Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 450,
        "is_active": 0,
        "uuid": "065816cf-a257-4737-a201-75108d262df0"
    },
    {
        "id": 41,
        "name": "Junior Track, Clockwise",
        "is_calibrated": false,
        "turns": 10,
        "length": 500,
        "is_active": 0,
        "uuid": "4f588e03-e058-4816-90c0-7da1be1c207d"
    },
    {
        "id": 42,
        "name": "Albert Park 1, Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 1,
        "uuid": "5396004e-9c24-41ea-a2fe-04438faa19f9"
    },
    {
        "id": 43,
        "name": "Albert Park 1, Anti-Clockwise",
        "is_calibrated": false,
        "turns": 15,
        "length": 350,
        "is_active": 0,
        "uuid": "1690d8ee-05cd-4d22-95fb-61adb1ae10d1"
    }
]

export const DefaultTrackConfig = "db3910ba-594d-4cea-b9a2-3ff9535925c6"