import { Fragment, useCallback, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useEventContext } from "./useEventsContext";
import Calender from "./Calender";
import { DateTime } from "luxon";
import { useFirebase } from "../../components/Firebase";
import {
  CollectionReference,
  collection,
  doc,
  setDoc,
} from "firebase/firestore";
import { TEvent } from "../../interfaces";
import { deepCopy } from "../../utilities";

const EventsModal = () => {
  const [isSendingNotifications, setIsSendingNotifications] = useState(false);
  const { modalSettings, dispatch, viewEventDetails, createEventsList } =
    useEventContext();
  const { eventName, eventClosure, date, isEmail, isSMS, enableEditing, id } =
    viewEventDetails;
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const eventDayRef = useMemo(
    () => collection(db, "event_day") as CollectionReference<TEvent>,
    [db]
  );


  const handleSaveChanges = async () => {
    const findAndUpdate = createEventsList.map((event: TEvent) => {
      if (event.id === id) {
        return { ...viewEventDetails, enableEditing: false } as TEvent;
      }
      return event;
    }) as TEvent[];
    await setDoc(doc(eventDayRef, id), viewEventDetails, { merge: true });

    // disable the editing mode
    dispatch({
      type: "SET_VIEW_EVENT_DETAILS",
      payload: {
        viewEventDetails: { ...viewEventDetails, enableEditing: false },
      },
    });
    // update the current list of events
    dispatch({
      type: "SET_CREATED_EVENTS_LISTS_STATE",
      payload: {
        createEventsList: findAndUpdate,
      },
    });
  };

  const formattedDate = date
    ? DateTime.fromMillis(date).toFormat("cccc, dd LLLL yyyy")
    : "-";  

  const selectedEvent =
    createEventsList &&
    createEventsList.length > 0 &&
    (createEventsList.find(
      (event: TEvent) => event.id === modalSettings.eventId
    ) as TEvent);

  const handleNotificationConfirmation = async () => {
    setIsSendingNotifications(true);
    try {
      if(selectedEvent){
        const getUpdatedEvent = (event:TEvent)=>{
          const newEvent = deepCopy(event) as TEvent;
          if(modalSettings.confirmationFor === "SMS"){
            newEvent.isSMS = !newEvent.isSMS;
          }
          if(modalSettings.confirmationFor === "Email"){
            newEvent.isEmail = !newEvent.isEmail;
          }
          return newEvent;
        }
        
        const findAndUpdate = deepCopy(createEventsList).map((event: TEvent) => {
          if (event.id === modalSettings.eventId) {
            return getUpdatedEvent(event);
          }
          return event;
        }) as TEvent[];

        await setDoc(doc(eventDayRef, selectedEvent.id), getUpdatedEvent(selectedEvent), { merge: true });
        
        // update the current list of events
        dispatch({
          type: "SET_CREATED_EVENTS_LISTS_STATE",
          payload: {
            createEventsList: findAndUpdate,
          },
        });

        // hide modal after receiving the confirmation
        dispatch({
          type: "SET_IS_MODAL_DISPLAYED",
          payload: { modalSettings: { ...modalSettings, displayModal: false } },
        });
      }
    } catch (error) {
      alert(error);
      console.log(error);
    } finally {
      setIsSendingNotifications(false);
    }
  };

  const handleSelect = useCallback((date: Date) => {
    dispatch({
        type: "SET_VIEW_EVENT_DETAILS",
        payload: {
          viewEventDetails: { ...viewEventDetails, date: DateTime.fromJSDate(date, { zone: "Australia/Sydney" }).endOf("day").toMillis() },
        },
      });
  },[dispatch, viewEventDetails]);

  return (
    <Transition appear show={modalSettings.displayModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() =>
          dispatch({
            type: "SET_IS_MODAL_DISPLAYED",
            payload: {
              modalSettings: { ...modalSettings, displayModal: false },
            },
          })
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center m-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full ${
                  modalSettings.displayFor === "Email Or SMS Confirmation"
                    ? "max-w-[370px]"
                    : "max-w-[600px]"
                } transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all max-h-[700px] h-full`}
              >
                {modalSettings.displayFor === "View Event Details" && (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-medium leading-6 text-gray-900 flex justify-between"
                    >
                      <div className="flex items-center gap-[10px]">
                        <span className="block">Event Details</span>
                        <button
                          type="button"
                          className={`capitalize py-[2px] px-[20px] rounded-full font-[500] bg-[black] text-white text-[14px]`}
                          onClick={() =>
                            dispatch({
                              type: "SET_VIEW_EVENT_DETAILS",
                              payload: {
                                viewEventDetails: {
                                  ...viewEventDetails,
                                  enableEditing: true,
                                },
                              },
                            })
                          }
                        >
                          edit
                        </button>
                      </div>
                      <div
                        onClick={() =>
                          dispatch({
                            type: "SET_IS_MODAL_DISPLAYED",
                            payload: {
                              modalSettings: {
                                ...modalSettings,
                                displayModal: false,
                              },
                            },
                          })
                        }
                        className="cursor-pointer ml-auto"
                      >
                        &times;
                      </div>
                    </Dialog.Title>
                    {enableEditing ? (
                      <div className="w-full mt-[50px]">
                        <div className="flex items-center justify-center gap-[10px]">
                          <div className="w-full">
                            <label
                              className="block text-[#2F1160] text-sm font-[700] mb-2"
                              htmlFor="event_name"
                            >
                              Event Name
                            </label>
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="event_name"
                              type="text"
                              placeholder="Enter event name"
                              onChange={(e) =>
                                dispatch({
                                  type: "SET_VIEW_EVENT_DETAILS",
                                  payload: {
                                    viewEventDetails: {
                                      ...viewEventDetails,
                                      eventName: e.target.value,
                                    },
                                  },
                                })
                              }
                              value={eventName}
                            />
                          </div>
                          <div className="w-full">
                            <label
                              className="block text-[#2F1160] text-sm font-[700] mb-2"
                              htmlFor="event_date"
                            >
                              Event Date
                            </label>
                            <Calender onDateChange={handleSelect} /> 
                          </div>
                        </div>
                        <div className="w-full mt-[20px]">
                          <label
                            className="block text-[#2F1160] text-sm font-[700] mb-2"
                            htmlFor="relevant_closures"
                          >
                            Relevant Closures
                          </label>
                          <textarea
                            id="relevant_closures"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={eventClosure}
                            onChange={(e) =>
                              dispatch({
                                type: "SET_VIEW_EVENT_DETAILS",
                                payload: {
                                  viewEventDetails: {
                                    ...viewEventDetails,
                                    eventClosure: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </div>
                        <div className="flex items-center justify-center gap-[10px] mt-[20px]">
                          <div className="w-full">
                            <span className="block text-[#2F1160] text-sm font-[700] mb-2">
                              SMS
                            </span>
                            <div
                              className="cursor-pointer block"
                              onClick={() =>
                                dispatch({
                                  type: "SET_VIEW_EVENT_DETAILS",
                                  payload: {
                                    viewEventDetails: {
                                      ...viewEventDetails,
                                      isSMS: !isSMS,
                                    },
                                  },
                                })
                              }
                            >
                              {isSMS ? (
                                <svg
                                  width="48"
                                  height="25"
                                  viewBox="0 0 48 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="48"
                                    height="25"
                                    rx="12.5"
                                    fill="#2EBF3C"
                                  />
                                  <circle
                                    cx="35.5"
                                    cy="12.5"
                                    r="10.5"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="48"
                                  height="25"
                                  viewBox="0 0 48 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="48"
                                    height="25"
                                    rx="12.5"
                                    fill="#D9D9D9"
                                  />
                                  <circle
                                    cx="12.5"
                                    cy="12.5"
                                    r="10.5"
                                    fill="white"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                          <div className="w-full">
                            <span className="block text-[#2F1160] text-sm font-[700] mb-2">
                              Email
                            </span>
                            <div
                              className="cursor-pointer block"
                              onClick={() =>
                                dispatch({
                                  type: "SET_VIEW_EVENT_DETAILS",
                                  payload: {
                                    viewEventDetails: {
                                      ...viewEventDetails,
                                      isEmail: !isEmail,
                                    },
                                  },
                                })
                              }
                            >
                              {isEmail ? (
                                <svg
                                  width="48"
                                  height="25"
                                  viewBox="0 0 48 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="48"
                                    height="25"
                                    rx="12.5"
                                    fill="#2EBF3C"
                                  />
                                  <circle
                                    cx="35.5"
                                    cy="12.5"
                                    r="10.5"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="48"
                                  height="25"
                                  viewBox="0 0 48 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="48"
                                    height="25"
                                    rx="12.5"
                                    fill="#D9D9D9"
                                  />
                                  <circle
                                    cx="12.5"
                                    cy="12.5"
                                    r="10.5"
                                    fill="white"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end items-center mt-[20px] gap-[10px]">
                          <button
                            type="button"
                            className="text-[14px] font-[700] py-[13px] px-[30px] text-[#9D62FE] border-[1px] border-[#9D62FE] rounded-full"
                            onClick={() =>
                              dispatch({
                                type: "SET_VIEW_EVENT_DETAILS",
                                payload: {
                                  viewEventDetails: {
                                    ...viewEventDetails,
                                    enableEditing: !enableEditing,
                                  },
                                },
                              })
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className={`text-[14px] font-[700] py-[13px] px-[30px] text-white bg-[#9D62FE] rounded-full cursor-pointer`}
                            onClick={handleSaveChanges}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-[20px]">
                        <div className="flex justify-between">
                          <div className="w-full text-left">
                            <label
                              htmlFor="event-name"
                              className="text-[#2F1160] text-[12px] font-[700] block"
                            >
                              Event Name
                            </label>
                            <p
                              id="event-name"
                              className="text-[#000000] block capitalize text-[14px] font-[500]"
                            >
                              {eventName}
                            </p>
                          </div>
                          <div className="w-full text-left">
                            <label
                              htmlFor="event-date"
                              className="text-[#2F1160] text-[12px] font-[700] block"
                            >
                              Event Date
                            </label>
                            <p
                              id="event-date"
                              className="text-[#000000] block capitalize text-[14px] font-[500]"
                            >
                              {formattedDate}
                            </p>
                          </div>
                        </div>
                        <div className="text-left mt-[20px]">
                          <label
                            htmlFor="event-closures"
                            className="text-[#2F1160] text-[12px] font-[700] block"
                          >
                            Relevant Closures
                          </label>
                          <p
                            id="event-closures"
                            className="text-[#000000] block capitalize text-[14px] font-[500]"
                          >
                            {eventClosure}
                          </p>
                        </div>
                        <div className="flex justify-between mt-[20px]">
                          <div className="w-full text-left">
                            <label
                              htmlFor="event-sms"
                              className="text-[#2F1160] text-[12px] font-[700] block"
                            >
                              SMS
                            </label>
                            <p
                              id="event-sms"
                              className="text-[#000000] block capitalize text-[14px] font-[500]"
                            >
                              {isSMS ? "Yes" : "No"}
                            </p>
                          </div>
                          <div className="w-full text-left">
                            <label
                              htmlFor="event-email"
                              className="text-[#2F1160] text-[12px] font-[700] block"
                            >
                              Email
                            </label>
                            <p
                              id="event-email"
                              className="text-[#000000] block capitalize text-[14px] font-[500]"
                            >
                              {isEmail ? "Yes" : "No"}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {modalSettings.displayFor === "Email Or SMS Confirmation" && (
                  <Dialog.Title
                    className={`font-[600] text-center text-[24px] text-[#393B41]`}
                  >
                    {selectedEvent &&
                    ((selectedEvent.isEmail &&
                      modalSettings.confirmationFor === "Email") ||
                      (selectedEvent.isSMS &&
                        modalSettings.confirmationFor === "SMS")) ? (
                      <>
                        Are you sure you want <br /> to turn off the {modalSettings.confirmationFor}?
                      </>
                    ) : (
                      <>
                        Are you sure you want <br /> to schedule the {modalSettings.confirmationFor}?
                      </>
                    )}
                    <div className="flex gap-[15px] justify-center mt-[20px]">
                      <button
                        type="button"
                        className="text-[14px] font-[700] py-[13px] px-[30px] text-[#9D62FE] border-[1px] border-[#9D62FE] rounded-full"
                        onClick={() =>
                          dispatch({
                            type: "SET_IS_MODAL_DISPLAYED",
                            payload: {
                              modalSettings: {
                                ...modalSettings,
                                displayModal: false,
                              },
                            },
                          })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="text-[14px] font-[700] py-[13px] px-[30px] text-white bg-[#9D62FE] rounded-full cursor-pointer"
                        onClick={handleNotificationConfirmation}
                      >
                        {isSendingNotifications ? "..." : "Confirm"}
                      </button>
                    </div>
                  </Dialog.Title>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default EventsModal;
