import { FC, useState } from "react";
import EditTextField from "../../components/Shared/EditTextField";
import { ICorporatesAndEvent, IModalConfig } from "../../interfaces/CorporatesAndEvents";
import { formatDateWithOrdinalSuffix } from "../../utilities/dateUtils";
import { useCorporatesAndEvents } from "../../context/CorporatesAndEvents";

const CorporatesAndEventsRow: FC<ICorporatesAndEvent> = ({
  id,
  name,
  eventDate,
  eventTime,
  eventType,
  activities,
  catering,
  note,
  isClosed,
  numberOfAttendees,
  shopifyOrderUrl,
}) => {
  const { dispatch, asyncDispatch, modalConfig } = useCorporatesAndEvents();
  const [statusFilter, setStatusFilter] = useState<"OPEN" | "CLOSE">("OPEN");

  const updateField = (id: string, fieldName: string, value: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          console.log(`Updating field '${fieldName}' for ID '${id}' with value: ${value}`);
          await asyncDispatch({
            type: "UPDATE_FIELD",
            payload: {
              id,
              fieldName,
              value,
            },
          });
          resolve();
          setStatusFilter("OPEN");
        } catch (error) {
          console.error("Error updating field:", error);
          setStatusFilter("CLOSE");
          reject(error);
        }
      }, 500);
    });
  };

  const handleViewEventConfig = () => {
    const modalPayloadConfig: IModalConfig = {
      ...modalConfig,
      modalOpeningConfig: {
        isModalOpen: true,
        modalOpenedFor: "VIEW_SELECTED_EVENT",
      },
      selectedModalData: {
        id,
        name,
        eventDate,
        eventTime,
        eventType,
        activities,
        catering,
        note,
        isClosed,
        numberOfAttendees,
        shopifyOrderUrl,
      },
    };
    dispatch({ type: "SET_MODAL_CONFIG", payload: modalPayloadConfig });
  };

  return (
    <tr>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">{name && <div className="truncate">{name}</div>}</div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">
            {formatDateWithOrdinalSuffix(eventDate)}, {eventTime}
          </div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="h-full truncate hover:text-gray-600">
          <EditTextField
            onSubmit={(val: string) => updateField(id, "note", val)}
            defaultValue={note}
            placeholder="Enter note Here"
            updatable={statusFilter === "OPEN"}
            type="text-area"
          />
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">{eventType && <div className="truncate">{eventType}</div>}</div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">{activities.filter((activity) => activity !== "Select All").join(", ")}</div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{catering === true ? "Yes" : "False"}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center h-full gap-2">
          <button
            className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-purple-500  hover:bg-purple-700 rounded-full"
            onClick={handleViewEventConfig}
          >
            View
          </button>
          {shopifyOrderUrl && (
            <a
              target="_blank"
              rel="noreferrer"
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-purple-500  hover:bg-purple-700 rounded-full"
              href={shopifyOrderUrl}
            >
              View on Shopify
            </a>
          )}
        </div>
      </td>
    </tr>
  );
};

export default CorporatesAndEventsRow;
