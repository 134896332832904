import React, { useMemo } from "react";
import { PageLayout } from "../../layouts";
import BookingSessionProvider, { useBookingSession } from "../../context/BookingSession";
import Header from "./Header";
import { TBookingType } from "../../interfaces";
import PriceTable from "./PriceTable";
import PriceChangerProvider, { usePricechanger } from "../../context/PriceChanger";
import ModalProvider from "../../context/Modal";
import "./style.css";
import { DateTime } from "luxon";
import { applyAgeFilter, getOrderIdsFromSessions } from "../../utilities";
import DriverRegistrationProvider from "../../context/DriverRegistration";
import SessionScheduleContextProvider from "../../context/SessionSchedule";

export function PriceChanger() {
  return (
    <PriceChangerProvider>
      <PriceChnagerComponent />
    </PriceChangerProvider>
  );
}

function PriceChnagerComponent() {
  const { view, selectedDate } = usePricechanger();
  const dateStr = useMemo(() => DateTime.fromJSDate(selectedDate).toFormat("yyyy-MM-dd"), [selectedDate]);
  return (
    <BookingSessionProvider date={selectedDate} track={view}>
      <SessionScheduleContextProvider date={dateStr} track={view}>
        <ModalProvider>
          <PriceChangerPage />
        </ModalProvider>
      </SessionScheduleContextProvider>
    </BookingSessionProvider>
  );
}

function PriceChangerPage() {
  const { selectedDate, changeSelectedDate, changeView } = usePricechanger();
  const { filteredSessions, track, ageFilter } = useBookingSession();

  const sessions = useMemo(() => applyAgeFilter(filteredSessions, ageFilter, track), [filteredSessions, ageFilter, track]);
  const orderIds = useMemo(() => getOrderIdsFromSessions(sessions), [sessions]);

  return (
    <DriverRegistrationProvider orderIds={orderIds}>
      <PageLayout
        Header={
          <Header
            selectedDate={selectedDate}
            changeSelectedDate={changeSelectedDate}
            changeView={(val) => {
              changeView(val as TBookingType);
            }}
          />
        }
      >
        <PriceTable />
      </PageLayout>
    </DriverRegistrationProvider>
  );
}
