import React, { useMemo } from 'react'
import { isBookedByMember } from './utilities'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Tooltip } from '@material-tailwind/react'
import { ICustomer, IOrderCustomer } from 'shopify-api-node'
import { classNames } from '../../components/Utilities'

const Name = ({ customer, hasMultipleBooking, refundedOrReturned, className = '' }: { customer?: ICustomer | IOrderCustomer, hasMultipleBooking: boolean, refundedOrReturned: boolean, className?: string }) => {
    const isMember = useMemo(() => isBookedByMember(customer), [customer])
    return (
        <div className={classNames(`truncate py-2 border-b flex flex-row items-center`, className)}>

            {(isMember && hasMultipleBooking) ? (
                <Tooltip content={"Multiple member bookings in a single session"}>
                    <p className={`${hasMultipleBooking ? "text-red-500": ""}`}>
                        {customer?.first_name}&nbsp;
                        {customer?.last_name}&nbsp;
                    </p>
                </Tooltip>
            ):(
                <p className={`${refundedOrReturned ? "line-through": ""}`}>
                    {customer?.first_name}&nbsp;
                    {customer?.last_name}&nbsp;
                </p>
            )}

            {(isMember && hasMultipleBooking) && <ExclamationCircleIcon fill="#F53B3B" width={"1rem"} className="mr-1" />}
        </div>
    )
}

export default Name