import { FC, useCallback } from "react";

interface Props {
  defaultValue?: boolean;
  checked: boolean;
  onChange?: (val: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  labelClassName?: string;
}
const Checkbox: FC<Props> = ({
  disabled = false,
  onChange,
  label,
  checked,
  className = "",
  labelClassName = "text-[#393B41]"
}) => {
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange && onChange(e.target.checked, e);
    },
    [onChange]
  );

  return (
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        className={`accent-[#9D62FE] w-4 h-4 ${className}`}
        onChange={changeHandler}
        disabled={disabled}
        checked={checked}
      />
      {!!label && (
        <label className={`text-xs not-italic font-medium leading-[normal] font-[Montserrat] ${labelClassName}`}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
