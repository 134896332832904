import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import { DateTime } from "luxon";
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";
import { useEventContext } from "./useEventsContext";

interface Props {
  defaultValue?: Date;
  onDateChange?: (date: Date) => void;
  layout?: "Create Event" | "View Event Details";
}

const Calender = ({ onDateChange, defaultValue, layout }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(defaultValue || new Date());
  const prevSelectedDateRef = useRef<Date | null>(null);
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const { dispatch, createEvent, viewEventDetails } = useEventContext();

  const handleSelect = useCallback(
    (date: Date) => {
      if (layout === "Create Event") {
        dispatch({
          type: "SET_CREATE_EVENT_STATE",
          payload: {
            createEvent: { ...createEvent, date: DateTime.fromJSDate(date, { zone: "Australia/Sydney" }).endOf("day").toMillis() },
          },
        });
      }
      if (layout === "View Event Details") {
        dispatch({
          type: "SET_VIEW_EVENT_DETAILS",
          payload: {
            viewEventDetails: { ...viewEventDetails, date: DateTime.fromJSDate(date, { zone: "Australia/Sydney" }).endOf("day").toMillis() },
          },
        });
      }
      setSelectedDate(date);
      setShowDateRange(false);
    },
    [createEvent, dispatch, layout, viewEventDetails]
  );

  const handlePrevDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).minus({ days: 1 });
    setSelectedDate(date.toJSDate());
    setShowDateRange(false);
  }, [selectedDate]);

  const handleNextDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).plus({ days: 1 });
    setSelectedDate(date.toJSDate());
    setShowDateRange(false);
  }, [selectedDate]);

  useEffect(() => {
    if (onDateChange && selectedDate !== prevSelectedDateRef.current) {
      onDateChange(selectedDate);
      prevSelectedDateRef.current = selectedDate;
    } else if (defaultValue && prevSelectedDateRef?.current && defaultValue !== prevSelectedDateRef?.current) {
      prevSelectedDateRef.current = defaultValue;
      setSelectedDate(defaultValue);
    }
  }, [defaultValue, onDateChange, selectedDate]);

  return (
    <>
      {layout === "Create Event" || layout === "View Event Details" ? (
        <>
          <div className="shadow appearance-none border rounded w-full py-2 px-3 flex justify-between cursor-pointer" onClick={() => setShowDateRange(true)}>
            <span className="cursor-pointer font-medium text-base leading-tight text-[#37415173]">
              {layout === "View Event Details" && viewEventDetails.date
                ? new Date(viewEventDetails.date).toDateString()
                : layout === "Create Event" && createEvent.date
                ? new Date(createEvent.date).toDateString()
                : selectedDate.toDateString()}
            </span>
            <CalendarIcon className="w-6 h-6 cursor-pointer text-[#9D62FE]" />
          </div>
          {showDateRange && (
            <>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowDateRange(false);
                }}
              >
                <Calendar className="absolute right-[10px] top-10 shadow-md z-50 border-[1px]" date={selectedDate} onChange={handleSelect} />
              </OutsideClickHandler>
            </>
          )}
        </>
      ) : (
        <>
          <div className="date-range-wrapper flex justify-center relative h-full">
            <div className="flex items-center bg-[#EBECEF] rounded-lg">
              <ChevronLeftIcon className="w-10 h-10 mr-2 cursor-pointer text-gray-800" onClick={handlePrevDayClick} />
              <span className="cursor-pointer font-medium text-base leading-tight text-gray-700" onClick={() => setShowDateRange(true)}>
                {selectedDate.toDateString()}
              </span>
              <ChevronRightIcon className="w-10 h-10 ml-2 cursor-pointer text-gray-800" onClick={handleNextDayClick} />
            </div>
            <div className="flex items-center ml-2 bg-[#EBECEF] rounded-lg p-3" onClick={() => handleSelect(new Date())}>
              <CalendarIcon className="w-6 h-6 cursor-pointer text-gray-800" />
            </div>
            {showDateRange && (
              <>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowDateRange(false);
                  }}
                >
                  <Calendar className="absolute right-0 top-10 shadow-md z-50" date={selectedDate} onChange={handleSelect} />
                </OutsideClickHandler>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Calender;
