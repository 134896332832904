import React, { useState } from "react";
import CorporatesAndEventsRow from "./CorporatesAndEventsRow";
import { useCorporatesAndEvents } from "../../context/CorporatesAndEvents";
import { ICorporatesAndEvent } from "../../interfaces/CorporatesAndEvents";

const CorporatesAndEventsTable: React.FC = () => {
  const { corporateAndEventsData } = useCorporatesAndEvents();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalItems = corporateAndEventsData?.displayToCustomerData?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentData = corporateAndEventsData?.displayToCustomerData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={`corporates-and-events-table overflow-auto h-[calc(100%_-_3.5rem)] m-7`}>
      {totalPages > 1 && (
        <div className="flex py-2 justify-end gap-4 mx-7 items-center">
          <p className="text-xs font-medium">
            Page: {currentPage} / {totalPages}
          </p>
          {currentPage > 1 && (
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center rounded-full bg-gray-500 hover:bg-gray-700"
              onClick={handlePrevPage}
            >
              Prev
            </button>
          )}
          {currentPage < totalPages && (
            <button
              className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center rounded-full bg-gray-500 hover:bg-gray-700"
              onClick={handleNextPage}
            >
              Next
            </button>
          )}
        </div>
      )}
      <table className="min-w-full h-[1px]">
        <thead>
          <tr>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Corporate and Event Name
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Date
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Notes
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Event Type
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Activities
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
              Catering
            </th>
            <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col"></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {currentData?.length > 0 && currentData.map((corporateAndEvent: ICorporatesAndEvent) => <CorporatesAndEventsRow key={corporateAndEvent.id} {...corporateAndEvent} />)}
        </tbody>
      </table>
    </div>
  );
};

export default CorporatesAndEventsTable;
