import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import { DateTime } from "luxon";
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon, ClipboardCopyIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";
import { Tooltip } from "@material-tailwind/react";
import { copyToClipboard } from "../../utilities";
import { isSameDay } from "date-fns";
import { Interval, getDayTypeIntervals, getIntervalForDate } from "../../utilities/dayType";
import { clearTime } from "../../utilities/dateUtils";

interface Props {
  defaultValue?: Date;
  value?: Date;
  copyText?: string;
  copyTooltip?: string;
  persistValue?: boolean;
  onDateChange?: (date: Date) => void;
  showCalendarIcon?: boolean;
}

const Calender = ({ onDateChange, defaultValue, value, copyText, copyTooltip, persistValue, showCalendarIcon = true }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(defaultValue || new Date());
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [dayTypeIntervals, setDayTypeIntervals] = useState<Interval[]>([]);
  const [isCopied, setIsCopied] = useState(false);

  const hasFetchedDayTypeIntervals = useRef(false);

  const getCustomDayContent = (day: Date) => {
    day = clearTime(day);

    const interval = getIntervalForDate(dayTypeIntervals, day);

    const isSelectedDay = isSameDay(selectedDate, day);
    const isToday = isSameDay(new Date(), day);

    const getColorForInterval = (interval: Interval | null) => {
      const DEFAULT_COLOR = "white";

      if (!interval) return DEFAULT_COLOR;
      const daytype = interval.type;

      switch (daytype) {
        case "Normal":
          return "white";
        case "School Holidays":
          return "orange";
        case "Christmas Season":
          return "orange";
        case "School Holidays January":
          return "orange";
        case "Public Holidays":
          return "red";
        case "Corporate Season":
          return "orange";
        default:
          return "white";
      }
    };

    const getBorderRadius = (interval: Interval | null) => {
      if (!interval || interval.type === "Normal") {
        return "1.333em";
      }

      const borderRadius = ["0px", "0px", "0px", "0px"];
      const dayOfWeek = day.getDay();
      // If start of week or start of interval, add left curve
      if (dayOfWeek === 1 || (interval && isSameDay(interval.startDate, day))) {
        borderRadius[0] = "1.333em";
        borderRadius[3] = "1.333em";
      }

      // If end of week or end of interval, add right curve
      if (dayOfWeek === 0 || (interval && isSameDay(interval.endDate, day))) {
        borderRadius[1] = "1.333em";
        borderRadius[2] = "1.333em";
      }

      //console.log(`Day: ${day.toDateString()}, borderRadius: ${borderRadius.join(" ")}`);
      return borderRadius.join(" ");
    };

    const Underline = () => {
      return (
        <div
          style={{
            position: "absolute",
            bottom: "1px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "18px",
            height: "2px",
            backgroundColor: !isSelectedDay ? "#3d91ff" : "white", // Underline color
            borderRadius: "2px", // Optional: rounded corners
          }}
        />
      );
    };

    let color = getColorForInterval(interval); // Default
    let textColor = "black"; // Default
    let borderRadius = getBorderRadius(interval);

    if (isSelectedDay) {
      textColor = "white";
      color = "#3d91ff"; // Light blue color to indicate that this is the selected day
      if (!interval || interval.type === "Normal") {
        borderRadius = "1.333em 1.333em 1.333em 1.333em";
      }
    }

    return (
      <div
        className="hover:border-[1px] hover:border-black"
        title={interval ? interval.type : ""}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "115%",
          borderRadius: borderRadius,
          background: color,
          zIndex: 1,
        }}
      >
        {isToday && Underline()}
        <p style={{ color: textColor, textDecoration: "none" }}>{day.getDate()}</p>
      </div>
    );
  };

  useEffect(() => {
    const fetchDayTypes = async () => {
      if (!hasFetchedDayTypeIntervals.current) {
        hasFetchedDayTypeIntervals.current = true;

        const intervals = await getDayTypeIntervals();
        console.log(intervals);
        setDayTypeIntervals(intervals);
      }
    };

    fetchDayTypes();
  }, [dayTypeIntervals]);

  const changeSelectedDate = useCallback(
    (date: Date) => {
      setSelectedDate(date);
      setShowDateRange(false);
      onDateChange && onDateChange(date);
    },
    [onDateChange]
  );

  const handleSelect = useCallback(
    (date: Date) => {
      changeSelectedDate(date);
    },
    [changeSelectedDate]
  );

  const handlePrevDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).minus({ days: 1 });
    changeSelectedDate(date.toJSDate());
  }, [selectedDate, changeSelectedDate]);

  const handleNextDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).plus({ days: 1 });
    changeSelectedDate(date.toJSDate());
  }, [selectedDate, changeSelectedDate]);

  const onCopyButtonClick = useCallback(() => {
    if (copyText) {
      copyToClipboard(
        copyText,
        () => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1000);
        },
        () => {
          setIsCopied(false);
          alert("Failed to copy text");
        }
      );
    }
  }, [copyText]);

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  useEffect(() => {
    if (persistValue) {
      const savedDate = localStorage.getItem("selectedDate");
      if (savedDate) {
        const dt = DateTime.fromJSDate(new Date(+savedDate));
        if (dt.isValid) {
          changeSelectedDate(dt.toJSDate());
        }
      }
    }
  }, [persistValue, changeSelectedDate]);

  useEffect(() => {
    if (persistValue && selectedDate) {
      localStorage.setItem("selectedDate", selectedDate.getTime().toString());
    }
  }, [selectedDate, persistValue]);

  return (
    <>
      <div className="date-range-wrapper flex justify-center relative h-full">
        <div className="flex items-center bg-[#EBECEF] rounded-lg">
          <ChevronLeftIcon className="w-10 h-10 mr-2 cursor-pointer text-gray-800" onClick={handlePrevDayClick} />
          <span className="cursor-pointer font-medium text-base leading-tight text-gray-700" onClick={() => setShowDateRange(true)}>
            {selectedDate.toDateString()}
          </span>
          <ChevronRightIcon className="w-10 h-10 ml-2 cursor-pointer text-gray-800" onClick={handleNextDayClick} />
        </div>
        {showCalendarIcon && (
          <div className="flex items-center ml-2 bg-[#EBECEF] rounded-lg p-3" onClick={() => handleSelect(new Date())}>
            <CalendarIcon className="w-6 h-6 cursor-pointer text-gray-800" />
          </div>
        )}

        {showDateRange && (
          <>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowDateRange(false);
              }}
            >
              <Calendar
                className="justify-center absolute right-0 top-10 shadow-md z-50"
                date={selectedDate}
                onChange={handleSelect}
                weekStartsOn={1}
                dayContentRenderer={getCustomDayContent}
              />
            </OutsideClickHandler>
          </>
        )}

        {!!copyText && (
          <Tooltip content={isCopied ? "Copied!" : copyTooltip}>
            <div className="flex items-center ml-2 bg-[#EBECEF] rounded-lg p-3 cursor-pointer" onClick={onCopyButtonClick}>
              <ClipboardCopyIcon className={`text-black w-6 ${isCopied ? "text-purple-500" : ""}`} />
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default Calender;
