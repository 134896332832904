import React, { useEffect, useMemo, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useFirebase } from "../Firebase";
import { collection, getDocs } from "firebase/firestore";
import { EventContentArg } from '@fullcalendar/core';

interface Event {
  title: string,
  description: string,
  start: Date,
  activities: string[]
}

function combineDateTime(eventDate: string, eventTime: string): Date | null {
  try {
    const dateObj = new Date(eventDate);
    let time = eventTime.trim();

    // 1. Check for am/pm
    const isAM = time.toLowerCase().includes('am');

    // 2. Split the string if there is a " " or "-", keep the first bit (don't care about end time)
    if (time.includes('-')) {
      time = time.split('-')[0].trim();
    } else if (time.includes(' ')) {
      time = time.split(' ')[0].trim();
    }

    // 3. Split the string again for ":". Convert this to hours and minutes (if there is no ":" then minutes = 0).
    let hours: number, minutes: number;
    if (time.includes(':')) {
      const parts = time.split(':').map(Number);
      hours = parts[0];
      minutes = parts[1];
    } else {
      hours = parseInt(time, 10);
      minutes = 0;
    }

    // 4. Assume pm where possible
    if (hours < 9 && !isAM) {
      hours += 12;
    }

    dateObj.setHours(hours, minutes, 0, 0);
    return dateObj;
  } catch (error) {
    console.error('Error parsing time:', eventTime, error);
    return null;
  }
}

export const FunctionCalendar = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);

  const fetchEvents = async (): Promise<Event[]> => {
    if (!db) return [];

    const eventsCollection = collection(db, 'corporates_and_events');
    const snapshot = await getDocs(eventsCollection);

    const fetchedEvents: Event[] = [];

    snapshot.forEach(doc => {
      const start = combineDateTime(doc.data().eventDate as string, doc.data().eventTime as string);

      if (!start) return;

      const fetchedEvent = {
        title: doc.data().name as string,
        start: start,
        description: doc.data().note as string,
        activities: doc.data().activities
      };

      fetchedEvents.push(fetchedEvent);
    });

    return fetchedEvents;
  }

  useEffect(() => {
    const fetchAndSetEvents = async () => {
      if (events.length > 0) return;

      const fetchedEvents = await fetchEvents();
      if (fetchedEvents) {
        console.log("Fetched events");
        console.log(events);
        setEvents(fetchedEvents);
      }
    };
    fetchAndSetEvents();
  }, );

  return  <>
  <div style={{ height: "100vh" }}> 
    <div className="xl:pl-64 flex flex-col">
      <main className="flex-1">
        <div className="pt-4 px-4 sm:px-6 lg:px-8">
          <div>
            <MyCalendar events={events}/> 
          </div>
        </div>
      </main>
    </div>
  </div>
</>
}

interface CalendarInterface {
  events: Event[]
}

const MyCalendar = ({ events }: CalendarInterface) => (
  <FullCalendar
    plugins={[dayGridPlugin, timeGridPlugin]}
    initialView="dayGridMonth"
    events={events}
    eventContent={renderEventContent}
    height="calc(100vh - 45px)"
  />
);

const renderEventContent = (eventContent: EventContentArg) => {
  const activities = eventContent.event._def.extendedProps["activities"];

  return (
    <div title={eventContent.event._def.extendedProps["description"]} style={{ overflow: "scroll", display: "flex", alignItems: "center", gap: "2px"}}>
      <div>{createActivityIndicators(activities)}</div>
      <b>{eventContent.timeText}m:  </b>
      <i> {eventContent.event.title}</i>
    </div>
  )
}

interface ColourMapping {
  [key: string]: string
}

const createActivityIndicators = (activities: string[]) => {
  const colourMap: ColourMapping = {
    "Main Track": "#40BFFF",
    "Mini Track": "#C03FFF",
    "VR": "#8A75FF",
    "Junior Track": "#00FFFF",
    "Intermediate Track": "#510EA8",
    "Venue Hire": "#FF00FF",
    "Select All": "#000000"
  }

  return activities.map((activity, index) => {
    const colour = colourMap[activity] || "gray";

    return <div key={index} style={{ width: "4px", height: "4px", borderRadius: "50%", backgroundColor: colour, display: "inline-block", margin: "2px"}}></div>
  });
}

