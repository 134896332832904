import axios from "axios";
import {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
  } from "react";
import { IRacefacerSession, IRacefacerScheduleResponse, IRacefacerSlot } from "../interfaces/racefacer";
import { TBookingType } from "../interfaces";
import { getSessionSubTrackId } from "../utilities";
  
  interface ISessionScheduleContextType {
    sessions: (IRacefacerSession | IRacefacerSlot)[]
  }
  
  const SessionScheduleContext = createContext<ISessionScheduleContextType>(undefined as unknown as ISessionScheduleContextType);
  
  const SessionScheduleContextProviderComponent: FC<PropsWithChildren<{ value: ISessionScheduleContextType }>> = ({ value, children }) => {
    return (
      <SessionScheduleContext.Provider value={value}>{children}</SessionScheduleContext.Provider>
    );
  };
  
  export const useSessionSchedule = () => {
    return useContext<ISessionScheduleContextType>(SessionScheduleContext);
  };
  
  interface Type {
    date: string; // YYYY-MM-DD
    track: TBookingType;
  }
  const SessionScheduleContextProvider: FC<PropsWithChildren<Type>> = ({ children, date, track }) => {
    const [sessions, setSessions] = useState<(IRacefacerSession | IRacefacerSlot)[]>([]);
    const sessionSubTrackId = useMemo(() => getSessionSubTrackId(track), [track])

    const updateSessions = useCallback(async (abortController: AbortController) => {
      if(!date) return;
      try {
        const { data } = await axios.get(`https://us-central1-hyper-karting.cloudfunctions.net/sessionsSchedule?date=${date}&session_sub_track_id=${sessionSubTrackId}`, { signal: abortController.signal })
        if(data.success && data?.data){
          const response = data.data as IRacefacerScheduleResponse;
          setSessions(response.schedule.data)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("Error fetching sessions", error);
        }
      }
    },[date, sessionSubTrackId])
    
    useEffect(() => {
      const abortController = new AbortController();
      updateSessions(abortController);
      const interval = setInterval(() => updateSessions(abortController), 1000 * 60) // refresh after 1 minute
      return () => {
        if(abortController.abort){
          abortController.abort();
        }
        if(interval){
          clearInterval(interval)
        }
      }
    }, [date, updateSessions])
  
    return (
      <SessionScheduleContextProviderComponent value={{ sessions }}>
        {children}
      </SessionScheduleContextProviderComponent>
    );
  };
  
  export default SessionScheduleContextProvider;
  