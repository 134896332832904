import axios from "axios";
import { useEffect, useRef, useState } from "react";

export const useOnlineRegistrationFormUser = (orderId: string | number) => {
  const [count, setCount] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const getOnlineUsers = async () => {
      try {
        // const url = "http://127.0.0.1:5001/hyper-karting/us-central1/registrationAppOnlineUsers";
        const url = "https://us-central1-hyper-karting.cloudfunctions.net/registrationAppOnlineUsers";

        const { data } = await axios.get(url, { params: { orderId } })
        if(data?.success){
            setCount(data.data);
        }
      } catch (error) {
        console.error("error", error);
      }
    }
    getOnlineUsers();
    intervalRef.current = setInterval(getOnlineUsers, 4000);
    return () => {
      if(intervalRef.current){
        clearInterval(intervalRef.current);
      }
    }
  }, [orderId])

  return { count };
}