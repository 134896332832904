import axios from "axios";
import { clearTime } from "./dateUtils";

export interface Interval {
	startDate: Date,
	endDate: Date,
	type: string
}

interface ResponseInterval {
	startDate: number,
	endDate: number,
	type: string
}

interface APIResponse {
	data: {
		success: boolean,
		data: ResponseInterval[]
	}
}

const convertTimestampToDateOnly = (timestamp: number) => {
	const date = new Date(timestamp * 1000);

	const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return new Date(year, month, day);
}

const parseIntervals = (responseIntervals: ResponseInterval[]): Interval[] => {
	return responseIntervals.map(interval => {
		return {
			startDate: convertTimestampToDateOnly(interval.startDate),
			endDate: convertTimestampToDateOnly(interval.endDate),
			type: interval.type
		}
	});
}
 
export const getDayTypeIntervals = async () => {
	const apiUrl = "https://us-central1-hyper-karting.cloudfunctions.net/dayTypes";
	//const apiUrl = "http://127.0.0.1:5001/hyper-karting/us-central1/dayTypes";

	try {
		const response: APIResponse = await axios.get(apiUrl);
		if (!response.data.success) return [];

		const intervals = parseIntervals(response.data.data);

		return intervals;
	} catch {
		return [];
	}
}

// Binary searches for an interval that contains the date, intervals are in ascending order of start date
export const getIntervalForDate = (intervals: Interval[], date: Date): Interval | null => {
  const normalisedDate = clearTime(date);

  let left = 0;
  let right = intervals.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const startDate = intervals[mid].startDate;
		const endDate = intervals[mid].endDate;

    // If the date is within the current interval, return it
    if (normalisedDate >= startDate && normalisedDate <= endDate) {
      return intervals[mid];
    }

    // If the date is earlier than the start of the middle interval, search in the left half
    if (normalisedDate < startDate) {
      right = mid - 1;
    } else {
      // If the date is later than the end of the middle interval, search in the right half
      left = mid + 1;
    }
  }

  return null;
};
