import { useEffect, useMemo, useState } from "react";
import { ISessionWithBookings } from "../../interfaces/Session";
import {
  getDriverCount,
  getGradientColor
} from "../../pages/dashboard/utilities";
import { TBookingType } from "../../interfaces";

const BookingDriverSeats = ({ session, view }: BookingDriverSeatsProps) => {
  const [totalDrivers, setTotalDrivers] = useState(0);
  const [bookedPercentage, setBookedPercentage] = useState(0);
  const seatsSoldColour = useMemo(() => getGradientColor("#00FFFF", "#FF00FF", bookedPercentage), [bookedPercentage]);

  useEffect(() => {
    let total = 0;
    session.bookings.forEach((x) => {
      const bookingDriverCount = getDriverCount(x);
      total += bookingDriverCount;
    });
    setTotalDrivers(total);

    const totalNumberOfSeats = total + Math.max(0, session.inventory_quantity);
    
    const percentage = (total / totalNumberOfSeats) * 100;
    setBookedPercentage(percentage);
  }, [session, view]);

  return (
    <div className="h-4 w-full flex relative">
      <div
        className="text-black absolute z-[9] text-right pr-2 font-medium text-xs leading-3 rounded-r-md"
        style={{
          width: `${bookedPercentage}%`,
          maxWidth: "100%",
          backgroundColor: seatsSoldColour,
          lineHeight: "16px",
        }}
      >
        {totalDrivers}
      </div>
    </div>
  );
};

interface BookingDriverSeatsProps {
  session: ISessionWithBookings;
  view: TBookingType;
}

export default BookingDriverSeats;
