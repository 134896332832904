import React, { useCallback } from "react";
import { RefreshIcon } from "@heroicons/react/outline";
import DropDown from "../../components/Shared/DropDown";
import SearchBar from "../../components/Shared/SearchBar";
import Button from "../../components/Shared/Button";
import { DateTime } from "luxon";
import { useCorporatesAndEvents } from "../../context/CorporatesAndEvents";
import { ICorporatesAndEvent } from "../../interfaces/CorporatesAndEvents";
import { sortByEventTime } from "./sortByEventTime";
import Calender from "../../components/Shared/Calender";

const CorporatesAndEventsHeader: React.FC = () => {
  const { dispatch, refresh, modalConfig, corporateAndEventsData, currentSortBy, selectedDate } = useCorporatesAndEvents();

  const handleRefresh = () => refresh();

  const applySearch = (val: string) => {
    const filterBySearch: ICorporatesAndEvent[] = [...corporateAndEventsData.displayToCustomerData].filter((eventData: ICorporatesAndEvent) =>
      eventData?.name?.toLowerCase()?.includes(val.toLowerCase())
    );

    dispatch({
      type: "LIST_ALL_EVENTS",
      payload: {
        ...corporateAndEventsData,
        displayToCustomerData: filterBySearch,
      },
    });
  };

  const handleSorting = useCallback(
    (sortBy: "ASCENDING" | "DESCENDING") => {
      dispatch({ type: "CURRENTLY_SORT_BY", payload: sortBy });

      let sortedData: ICorporatesAndEvent[] = [...corporateAndEventsData.displayToCustomerData];

      sortedData = sortByEventTime(sortedData, sortBy);

      dispatch({
        type: "LIST_ALL_EVENTS",
        payload: {
          ...corporateAndEventsData,
          displayToCustomerData: sortedData,
        },
      });

      console.log("handleSorting", sortedData);
    },
    [corporateAndEventsData, dispatch]
  );

  const handleChangeDate = useCallback(
    (date: Date) => {
      const newSelectedDate = DateTime.fromJSDate(date, { zone: "Australia/Sydney" }).toISODate();

      dispatch({ type: "SELECTED_DATE", payload: date });

      let filteredData: ICorporatesAndEvent[] = corporateAndEventsData.originalData.filter((event) => DateTime.fromISO(event.eventDate).toISODate() === newSelectedDate);

      filteredData.sort((a, b) => {
        const dateA = DateTime.fromISO(a.eventDate);
        const dateB = DateTime.fromISO(b.eventDate);
        return currentSortBy === "ASCENDING" ? dateA.toMillis() - dateB.toMillis() : dateB.toMillis() - dateA.toMillis();
      });

      console.log("change event", filteredData);

      dispatch({
        type: "LIST_ALL_EVENTS",
        payload: {
          ...corporateAndEventsData,
          displayToCustomerData: filteredData,
        },
      });
    },
    [corporateAndEventsData, currentSortBy, dispatch]
  );

  const handleOpenModal = () =>
    dispatch({
      type: "SET_MODAL_CONFIG",
      payload: {
        modalOpeningConfig: {
          isModalOpen: true,
          modalOpenedFor: "CREATE_NEW_EVENT",
        },
        newModalData: {
          ...modalConfig.newModalData,
          eventDate: `${DateTime.fromJSDate(new Date(), { zone: "Australia/Sydney" }).toISO()}`,
          eventTime: `${new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()}:${
            new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()
          }`,
        } as ICorporatesAndEvent,
        selectedModalData: null,
      },
    });

  return (
    <>
      <div className="flex min-w-3 w-[100%] items-center gap-4 h-12">
        <div className="w-1/3 h-full">
          <SearchBar onChange={applySearch} />
        </div>

        <div className="flex-1"></div>
        <Calender onDateChange={handleChangeDate} defaultValue={selectedDate} />
        <div className="flex-1"></div>

        <DropDown
          items={[
            {
              key: "ASCENDING",
              label: "Ascending",
            },
            {
              key: "DESCENDING",
              label: "Descending",
            },
          ]}
          selected={currentSortBy}
          onSelect={(val) => handleSorting(val as "ASCENDING" | "DESCENDING")}
        />
        <Button onClick={handleOpenModal}>Create Event</Button>

        <div className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3">
          <RefreshIcon className="text-white w-6" onClick={handleRefresh} />
        </div>
      </div>
    </>
  );
};

export default CorporatesAndEventsHeader;
