export const BOOKING_TYPES = ["MAIN_TRACK", "MINI_TRACK", "VR", "JUNIOR_TRACK", "INTERMEDIATE_TRACK"] as const;

export type TTrack = "MAIN_TRACK" | "MINI_TRACK" | "JUNIOR_TRACK" | "INTERMEDIATE_TRACK";
export type TBookingType = (typeof BOOKING_TYPES)[number];
export type TDashboardView = "SIMPLE_VIEW" | "COMPLEX_VIEW";
export type TArcadeGameCardFilters = "ARCHIVED" | "NOT_ARCHIVED";

export type TArcadeGameCardSortOptions = "ASCENDING" | "DESCENDING";
export enum EVrIssueSortOptions {
  "ASCENDING" = "ASCENDING",
  "DESCENDING" = "DESCENDING",
}
export enum ELogSortOptions {
  "ASCENDING" = "ASCENDING",
  "DESCENDING" = "DESCENDING",
}
export enum EDifficulty {
  "-5%" = "0.95",
  "-2.5%" = "0.975",
  "Normal" = "1.0",
  "+2.5%" = "1.025",
  "+5%" = "1.05",
}

export type TIssueStatus = "OPEN" | "CLOSED";
export enum EIssueType {
  VR = "VR_ISSUE",
}
export enum ETower {
  "A" = "A",
  "B" = "B",
}
export enum EVrControllerType {
  "LEFT" = "LEFT",
  "RIGHT" = "RIGHT",
}

export enum TMainTrackAgeFilters {
  "ALL" = "All Sessions",
  "Ages 16+" = "Ages 16+",
  "Ages 12-15" = "Ages 12-15",
  "140cm+ | Ages under 12" = "140cm+ | Ages under 12",
}

export enum TMainTrackRaceTypeFilter {
  "Standard" = "Standard",
  "Expert" = "Expert",
}

export type TRoles = "TRACK_STAFF" | "STAFF"; //Added for pa@hyperkarting.com.au and pitcrew@hyperkarting.com.au

export type IFeatures = "CHECKIN" | "RRM" | "SMS_LOGS" | "SPEED_CONTROL_SCRIPT_MANAGE" | "EVENT_DAY_TAB";

export interface IPermission {
  roles?: TRoles[];
  features?: IFeatures[];
}

export const LOG_TYPES = [
  "PRICE_CHANGER",
  "RACE_READY_MESSAGE",
  "HK_REFUND",
  "DRIVER_ADDED_TO_SESSION_MESSAGE",
  "GOOGLE_REVIEW_MESSAGE",
  "DRIVER_REGISTER_MESSAGE",
  "QUANTITY_ADJUSTED",
  "VR_ISSUE",
  "VR_ISSUE_UPDATED",
  "VR_ISSUE_RESOLVED",
  "ARCADE_CARD_NUMBER_SUBMITTED",
  "VARIANT_UPDATE",
  "DRIVER_REGISTRATION_REMINDER",
  "EVENT_DAY_SMS_NOTIFICATION",
  "REFUND_REMINDER",
  "BRAZE_RACEFACER_RESULT_SYNC",
  "SESSION_EDITOR",
  "RACE_FINAL_REMINDER_EVENT"
] as const;

export type TLogType = (typeof LOG_TYPES)[number];

export type TTransactionStatus = "IN_PROGRESS" | "COMPLETED" | "FAILED";

export type TObjFromArray<T> = {
  [key: string]: T;
};

export type TEvent = {
  id: string;
  eventName: string;
  date: number;
  eventClosure: string;
  isSMS: boolean;
  isEmail: boolean;
  isClosed: boolean;
  enableEditing?: boolean;
};

export interface IPriceRecommendation {
  VARIANT_ID: number;
  VARIANT_SKU: string;
  VARIANT_RECOMMENDED_PRICE: number;
  PERCENTAGE: number;
}