import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IPriceRecommendation, TBookingType } from "../interfaces";
import axios from "axios";
import { useSession } from "../components/Session";
import { IProductVariant } from "shopify-api-node";
import { getDatetimeFromSku } from "../utilities";
import { DefaultTrackConfig } from "../constants";
import { useFirebase } from "../components/Firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import { ISessionWithBookings } from "../interfaces/Session";
import { IFirebaseRFSessionItem, IFirebaseRFSessions } from "../interfaces/firebaseEntities";

type IItem =
  | { variantId: string; price: number }
  | { variantId: string; quantity: number }
  | { variantId: string; option1: string }
  | { variantId: string; option2: string }
  | { variantId: string; option3: string }
  | { variantId: string; trackConfig: { sku: string; value: string } };
interface IItemState {
  price: { [key: string]: number };
  quantity: { [key: string]: number };
  option1: { [key: string]: string };
  option2: { [key: string]: string };
  option3: { [key: string]: string };
  trackConfig: { [key: string]: { sku: string; value: string } };
}

const defaultItems: IItemState = { price: {}, quantity: {}, option1: {}, option2: {}, option3: {}, trackConfig: {} };

export interface IPriceChangerContext {
  isUpdating: boolean;
  view: TBookingType;
  selectedDate: Date;
  selectedDateForCompareProduct: Date;
  changeView: (val: TBookingType) => void;
  changeSelectedDate: (val: Date, type?: "product" | "compareProduct") => void;
  toggleItem: (data: IItem) => void;
  removeItemByVariant: (variantId: string, type?: keyof IItemState) => void;
  clearItems: () => void;
  updateItems: (productId: number, scheduleSessions: IFirebaseRFSessionItem[], cb: () => void) => void;
  recommendedPrices: IPriceRecommendation[];
  items: IItemState;
  getBgColor: (view: TBookingType) => string;
  isUpdateButtonActive: boolean;
  isSessionSelected: (variantId: string | number) => boolean;
  config: Record<string, string>[][];
  selectedTrackConfig: string;
  updateSelectedTrackConfig: (val: string, trackConfigChangeableSessions: ISessionWithBookings[]) => Promise<void>;
  rfSessions: IFirebaseRFSessions | null;
}

const PriceChangerContext = React.createContext<IPriceChangerContext>({
  isUpdating: false,
  view: "MAIN_TRACK",
  selectedDate: new Date(),
  selectedDateForCompareProduct: new Date(),
  changeView: (val: TBookingType) => {},
  changeSelectedDate: (val: Date, type?: "product" | "compareProduct") => {},
  toggleItem: (data: IItem) => {},
  removeItemByVariant: () => {},
  clearItems: () => {},
  updateItems: (id: number, scheduleSessions: IFirebaseRFSessionItem[], cb: () => void) => {},
  recommendedPrices: [],
  items: defaultItems,
  getBgColor: () => "",
  isUpdateButtonActive: false,
  isSessionSelected: () => false,
  config: [],
  selectedTrackConfig: "",
  updateSelectedTrackConfig: (val: string, trackConfigChangeableSessions: ISessionWithBookings[]) => Promise.resolve(),
  rfSessions: null,
});

interface Type {
  children: ReactNode;
}

const PriceChangerProvider: FC<Type> = ({ children }) => {
  const [view, setView] = useState<TBookingType>("MAIN_TRACK");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedDateForCompareProduct, setSelectedDateForCompareProduct] = useState<Date>(new Date());
  const [items, setItems] = useState<IItemState>(defaultItems);
  const [isUpdating, setIsUpdating] = useState(false);
  const [recommendedPrices, setRecommendedPrices] = useState([]);
  const [config, setConfig] = useState<Record<string, string>[][]>([]);
  const [selectedTrackConfig, setSelectedTrackConfig] = useState<string>(DefaultTrackConfig);
  const [rfSessions, setRfSessions] = useState<IFirebaseRFSessions | null>(null);

  const { getToken } = useSession();
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const rfSessionsRef = useMemo(() => collection(db, "rf_sessions"), [db]);
  const isUpdateButtonActive = useMemo(
    () =>
      !!(Object.keys(items.price).length || Object.keys(items.quantity).length) ||
      !!(Object.keys(items.option1).length || Object.keys(items.option2).length || Object.keys(items.option3).length || Object.keys(items.trackConfig).length),
    [items]
  );
  const dateStr = useMemo(() => DateTime.fromJSDate(selectedDate, { zone: "Australia/Sydney" }).toFormat("yyyy-MM-dd"), [selectedDate]);

  console.log("items", items);

  const isSessionSelected = useCallback(
    (variantId: string | number) =>
      !!(items.price[variantId] || items.quantity[variantId] || items.option1[variantId] || items.option2[variantId] || items.option3[variantId] || items.trackConfig[variantId]),
    [items]
  );

  const toggleItem = useCallback((data: IItem) => {
    setItems((oldState) => {
      const newState = JSON.parse(JSON.stringify(oldState));
      const variantId = String(data.variantId);

      (Object.keys(data) as Array<keyof IItem>).forEach((key) => {
        if (key !== "variantId" && key in newState) {
          const stateKey = key as keyof IItemState;

          if (stateKey === "trackConfig") {
            const trackConfigData = data[key] as { sku: string; value: string };
            if (newState.trackConfig[variantId]?.value === trackConfigData.value) {
              delete newState.trackConfig[variantId];
            } else {
              newState.trackConfig[variantId] = trackConfigData;
            }
          } else {
            if (newState[stateKey][variantId] === data[key]) {
              delete newState[stateKey][variantId];
            } else {
              newState[stateKey][variantId] = data[key] as any;
            }
          }
        }
      });

      return newState;
    });
  }, []);

  const removeItemByVariant = useCallback((variantId: string, type?: keyof IItemState) => {
    setItems((oldState) => {
      const newState = JSON.parse(JSON.stringify(oldState));
      if (type) {
        if (Object.keys(newState[type]).includes(variantId)) {
          delete newState[type][variantId];
        }
      } else {
        (Object.keys(newState) as Array<keyof IItemState>).forEach((key) => {
          delete newState[key][variantId];
        });
      }
      return newState;
    });
  }, []);

  const clearItems = useCallback(() => {
    setItems(defaultItems);
  }, []);

  const changeView = useCallback((val: TBookingType) => {
    setView(val);
    setItems(defaultItems);
  }, []);

  const changeSelectedDate = useCallback(async (val: Date, type?: "product" | "compareProduct") => {
    if (type === "product") {
      setSelectedDate(val);
      setItems(defaultItems);
    }
    if (type === "compareProduct") {
      setSelectedDateForCompareProduct(val);
    }
  }, []);

  const getBgColor = useCallback((view: TBookingType) => (view === "MAIN_TRACK" ? "bg-[#F609FF]" : view === "MINI_TRACK" ? "bg-[#9D62FE]" : "bg-[#41BDFE]"), []);

  const refreshRfSessions = useCallback(async () => {
    try {
      const snapshot = await getDoc(doc(rfSessionsRef, dateStr));
      if(snapshot && snapshot.exists()){
        const rfSessionsData = snapshot.data() as IFirebaseRFSessions;
        if(rfSessionsData){
          setRfSessions(rfSessionsData);
          setSelectedTrackConfig(rfSessionsData.default_track_configuration_uuid || DefaultTrackConfig);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },[dateStr, rfSessionsRef])

  const updateItems = useCallback(
    async (productId: number, rfSessions: IFirebaseRFSessionItem[], cb: () => void) => {
      try {
        const token = await getToken();
        if (!token) {
          return {
            status: false,
            message: "Token not generated",
          };
        }
        setIsUpdating(true);
        const variants: { id: number; updates: Partial<IProductVariant> }[] = [];

        const updateFields: (keyof Omit<IItemState, "trackConfig">)[] = ["price", "quantity", "option1", "option2", "option3"];

        updateFields.forEach((field) => {
          Object.entries(items[field]).forEach(([variantId, value]) => {
            const sanitizedKey: keyof IProductVariant = field === "quantity" ? "inventory_quantity" : field;
            const sanitizedVal = field === "price" ? String(value) : value;

            const id = Number(variantId);
            const existingVariant = variants.find((v) => v.id === id);
            if (existingVariant) {
              existingVariant.updates[sanitizedKey] = sanitizedVal;
            } else {
              variants.push({
                id,
                updates: { [sanitizedKey]: sanitizedVal },
              });
            }
          });
        });

        const trackConfig: { session_id: string; track_configuration_id: string }[] = [];
        Object.values(items?.trackConfig || {}).forEach(({ sku, value }) => {
          console.log("sku", sku, value, items?.trackConfig);
          const dt = getDatetimeFromSku(sku, view);
          if (dt) {
            const scheduleSession = rfSessions.find((s) => s.start_time_key.includes(dt.toFormat("yyyy-MM-dd HH:mm")));
            if (scheduleSession) {
              trackConfig.push({ session_id: scheduleSession.uuid, track_configuration_id: value });
            }
          }
        });

        await axios.post(
          // "http://127.0.0.1:5001/hyper-karting/us-central1/sessionEditorV2",
          "https://us-central1-hyper-karting.cloudfunctions.net/sessionEditorV2",
          { productId, variants, trackConfig },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if(trackConfig.length){
          for(const config of trackConfig){
            if(config.track_configuration_id !== selectedTrackConfig){
              const session = rfSessions.find(s => s.uuid === config.session_id);
              if(session){
                session.track_configuration_uuid = config.track_configuration_id;
              }

              await setDoc(doc(rfSessionsRef, dateStr), { sessions: rfSessions }, { merge: true });
            }
          }
        }
        await setDoc(doc(rfSessionsRef, dateStr), { default_track_configuration_uuid: selectedTrackConfig }, { merge: true });
        await refreshRfSessions();

        setIsUpdating(false);
        setItems(defaultItems);
        cb();
      } catch (error) {
        setIsUpdating(false);
        return {
          status: false,
          message: error,
        };
      }
    },
    [items, view, getToken, selectedTrackConfig, dateStr, rfSessionsRef, refreshRfSessions]
  );

  const updateSelectedTrackConfig = useCallback(async (val: string, trackConfigChangeableSessions: ISessionWithBookings[]) => {
    setSelectedTrackConfig(val);
    const trackConfigToUpsert = trackConfigChangeableSessions.reduce((acc, session) => ({ ...acc, [session.id]: { sku: session.sku, value: val } }), {});
    setItems((prev) => ({ ...prev, trackConfig: { ...prev.trackConfig, ...trackConfigToUpsert } }));
  }, []);
  
  useEffect(()=>{
    refreshRfSessions()
  },[refreshRfSessions])

  useEffect(() => {
    // axios.get("http://127.0.0.1:5001/hyper-karting/us-central1/recommendedVariantsPrice?ts="+selectedDate.getTime()).then(({data})=>{
    axios
      .get("https://us-central1-hyper-karting.cloudfunctions.net/recommendedVariantsPrice?ts=" + selectedDate.getTime())
      .then(({ data }) => {
        if (data.success) {
          setRecommendedPrices(data.data);
          const config = data?.config;
          if (config?.length) {
            setConfig(config);
          }
        }
      })
      .catch(console.log);
  }, [selectedDate]);

  return (
    <PriceChangerContext.Provider
      value={{
        isUpdating,
        changeSelectedDate,
        changeView,
        selectedDate,
        selectedDateForCompareProduct,
        toggleItem,
        view,
        removeItemByVariant,
        clearItems,
        updateItems,
        recommendedPrices,
        items,
        getBgColor,
        isUpdateButtonActive,
        isSessionSelected,
        config,
        selectedTrackConfig,
        updateSelectedTrackConfig,
        rfSessions,
      }}
    >
      {children}
    </PriceChangerContext.Provider>
  );
};

export function usePricechanger() {
  return useContext(PriceChangerContext);
}

export default PriceChangerProvider;
