import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBookingSession } from "../../context/BookingSession";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import { TBookingType, TDashboardView, TMainTrackAgeFilters } from "../../interfaces";
import { ILineItemWithOrder, ISessionWithBookings } from "../../interfaces/Session";
import { BookingDriverSeats } from "../../components/Common";
import Session from "./Session";
import { useSession as useAuthSession } from "../../components/Session";
import { useDevice } from "../../hooks/useDevice";
import { DateTime } from "luxon";
import { applyAgeFilter, getDatetimeFromSku, getOrderIdsFromSessions } from "../../utilities";
import DriverRegistrationProvider from "../../context/DriverRegistration";
import BookingDetailsModal from "./BookingDetailsModal";
import DriverRegistrationModal from "./DriverRegistrationModal";
import { IFirebaseDraftOrder } from "../../../functions/src/interfaces/firebaseEntities";
import { useModal } from "../../context/Modal";
import Button from "../../components/Shared/Button";
import Checkbox from "../../components/Shared/Checkbox";
import axios from "axios";

interface Props {
  dashboardView: TDashboardView;
  ageFilter: TMainTrackAgeFilters;
  isItemExchangeEnable: boolean;
  changeAgeFilter: (val: TMainTrackAgeFilters) => void;
  changeIsItemExchangeEnable: (val: boolean) => void;
}

function SessionTable({ dashboardView, ageFilter,isItemExchangeEnable, changeAgeFilter, changeIsItemExchangeEnable }: Props) {
  const { loading, filteredSessions, track: view, date, originalSessions, draftOrders, refreshSessions } = useBookingSession();
  const [selectedBooking, setSelectedBooking] = useState<ILineItemWithOrder | IFirebaseDraftOrder | null>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showRegistrationModal, setRegistrationModal] = useState(false);
  const [registrationView, setRegistrationView] = useState<TBookingType>(view);
  const sessionRowsRef = useRef<Array<HTMLTableRowElement | null>>([]);
  const { permissions } = useAuthSession();
  const trackStaff = permissions?.roles?.includes("TRACK_STAFF");
  const hasRrmFeatureAccess = permissions?.features?.includes("RRM");
  const ageOptions = useMemo(() => (view === "MAIN_TRACK" ? Object.values(TMainTrackAgeFilters) : ["All Sessions"]), [view]);
  const isComplexView = useMemo(() => dashboardView === "COMPLEX_VIEW", [dashboardView]);
  const { setModalData, setshowModal } = useModal();

  const { isIPad, isMobile } = useDevice();

  const totalNoOfColumns = useMemo(() => {
    let numOfPublicColumn = 9;

    if (!isIPad) {
      numOfPublicColumn += 1;
    }

    if (isComplexView) {
      numOfPublicColumn += 3; //bookingtime, paid_price, barcode
    }

    //racereadymsg
    if (!trackStaff || hasRrmFeatureAccess) {
      numOfPublicColumn += 1;
    }

    return numOfPublicColumn;
  }, [isComplexView, trackStaff, hasRrmFeatureAccess, isIPad]);

  const sessions = useMemo(() => applyAgeFilter(dashboardView ? originalSessions : filteredSessions, ageFilter, view),[filteredSessions, ageFilter, view, originalSessions, dashboardView]);
  const orderIds = useMemo(() =>getOrderIdsFromSessions(sessions),[sessions]);

  useEffect(() => {
    if(isMobile){
      return 
    }
    const dtNow = DateTime.local({
      zone: "Australia/Sydney",
    });

    if (sessions.length && DateTime.fromJSDate(date).toFormat("dd-MM-yyyy") === dtNow.toFormat("dd-MM-yyyy")) {
      const currentSession = sessions.reduce((prevVal, currVal) => {
        const prevDt = getDatetimeFromSku(prevVal.sku, view);
        const currDt = getDatetimeFromSku(currVal.sku, view);

        if (dtNow.toMillis() - (currDt?.toMillis() || 0) < dtNow.toMillis() - (prevDt?.toMillis() || 0) && dtNow.toMillis() - (currDt?.toMillis() || 0) > 0) {
          return currVal;
        }

        return prevVal;
      }, sessions[0]);

      sessionRowsRef.current = sessionRowsRef.current.slice(0, sessions.length);

      const currentSessionIndex = sessionRowsRef.current.findIndex((sessiondiv) => sessiondiv?.dataset?.sku === currentSession.sku);

      sessionRowsRef.current[currentSessionIndex - 1 >= 0 ? currentSessionIndex - 1 : 0]?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [sessions, view, date, isMobile]);

  const handleViewOrderClick = useCallback((booking: ILineItemWithOrder | IFirebaseDraftOrder) => {
    setSelectedBooking(booking);
    setShowDetailsModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowDetailsModal(false);
    setSelectedBooking(null);
  }, []);

  const handleViewRegistrationModalOpen = useCallback((booking: ILineItemWithOrder) => {
    setSelectedBooking(booking);
    setRegistrationModal(true);
    setRegistrationView(view);
  }, [view]);

  const handleModalRegistrationModalClose = useCallback(() => {
    setRegistrationModal(false);
    setSelectedBooking(null);
  }, []);

  const handleActivityChange = useCallback((newView: TBookingType) => {
    setRegistrationView(newView);
  }, []);

  const handleDragDrop = useCallback((from: ILineItemWithOrder, to: ISessionWithBookings) => {
    console.log("dragDrop", from, to);
    if(from.variant_id === to.id){
      return;
    }

    setshowModal(true);
    setModalData({
      id: "ORDER_ITEM_EXCHANGE_CONFIRMATION",
      title: "Exchange Booking",
      onClose: () => setshowModal(false),
      content: <ExchangeConfirmationModalContent from={from} to={to} onCancel={() => setshowModal(false)} onConfirm={refreshSessions} />,      
    });
  }, [setModalData, setshowModal, refreshSessions]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <DriverRegistrationProvider orderIds={orderIds}>
      <div className="flex flex-col h-full">
        <div className="flex px-7 py-3">
          <div>
            <div className="cursor-pointer bg-gray-300 rounded-md p-2" onClick={() => changeIsItemExchangeEnable(!isItemExchangeEnable)}>
              <Checkbox label="Enable exchange" checked={isItemExchangeEnable} onChange={changeIsItemExchangeEnable} labelClassName="cursor-pointer" />
            </div>
          </div>
        </div>
        <div className="px-7 pb-7 overflow-hidden flex-1">
          <div className="dashboard-session-table-wrapper overflow-auto h-full">
            {/* models */}
            {!!selectedBooking && showDetailsModal && 'order' in selectedBooking && (
              <BookingDetailsModal
                modal="ORDER_DETAIL"
                show={showDetailsModal}
                order={(selectedBooking as ILineItemWithOrder).order}
                handleClose={handleModalClose}
                handleViewRegistrationModalOpen={handleViewRegistrationModalOpen}
              />
            )}
            {!!selectedBooking && showDetailsModal && !('order' in selectedBooking) && (
              <BookingDetailsModal
                modal="ORDER_DETAIL"
                show={showDetailsModal}
                order={selectedBooking as IFirebaseDraftOrder}
                handleClose={handleModalClose}
                handleViewRegistrationModalOpen={handleViewRegistrationModalOpen}
              />
            )}

            {!!selectedBooking && showRegistrationModal && 'order' in selectedBooking && (
              <DriverRegistrationModal
                show={showRegistrationModal}
                order={selectedBooking.order}
                handleClose={handleModalRegistrationModalClose}
                view={registrationView}
                selectedDate={date}
                handleViewOrderClick={handleViewOrderClick}
                onActivityChange={handleActivityChange}
              />
            )}
            {/* models */}
            <table className="min-w-full h-[1px]">
              <thead>
                <tr>
                  {!isIPad && isComplexView && (
                    <th className="min-w-[160px] p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                      QR Code
                    </th>
                  )}

                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                    Staff
                  </th>
                  {/* TODO: add filter based on view and filter the session data */}
                  <th className="min-w-[140px] p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                    <select
                      className="mt-1 w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={ageFilter}
                      onChange={(e) => changeAgeFilter(e.target.value as TMainTrackAgeFilters)}
                    >
                      {ageOptions.map((opt) => {
                        return <option key={opt}>{opt}</option>;
                      })}
                    </select>
                  </th>

                  <th className="min-w-[72px] p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                    Price
                  </th>
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                    Qty
                  </th>
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                    Booking name
                  </th>
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                    Notes
                  </th>
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                    Occasion
                  </th>
                  {isComplexView && (
                    <>
                      <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                        Booking Time
                      </th>
                      <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 whitespace-nowrap" scope="col">
                        Price Paid
                        <br />
                        (Average Price)
                      </th>
                    </>
                  )}
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                    DRIVERS
                  </th>
                  {(!trackStaff || hasRrmFeatureAccess) && (
                    <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                      Race Ready SMS
                    </th>
                  )}
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                    Registration status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                {(sessions || []).map((session: ISessionWithBookings, index) => (
                    <Fragment key={session.id}>
                      <Session
                        session={session}
                        draftOrders={draftOrders.filter(draftOrder => draftOrder.status !== 'completed' && draftOrder.line_items.find(li => li.variant_id === session.id))} //draftOrder.status !== "completed"
                        dashboardView={dashboardView}
                        handleViewOrderClick={handleViewOrderClick}
                        handleViewRegistrationModalOpen={handleViewRegistrationModalOpen}
                        handleDragDrop={handleDragDrop}
                        isItemExchangeEnable={isItemExchangeEnable}
                      />
                      <tr ref={(el) => (sessionRowsRef.current[index] = el)} data-sku={session.sku} className="scroll-mt-[35px]">
                        <td colSpan={totalNoOfColumns} className="p-0 ">
                          <BookingDriverSeats session={session} view={view} />
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                {sessions.length === 0 && (
                  <tr>
                    <td colSpan={totalNoOfColumns}>
                      <div className="text-lg text-gray-400 text-center h-screen mt-[20%]">No records to display</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DriverRegistrationProvider>
  );
}

const ExchangeConfirmationModalContent = ({ from, to, onCancel, onConfirm }: { from: ILineItemWithOrder, to: ISessionWithBookings, onCancel: () => void, onConfirm: () => void }) => {
  const [loading, setLoading] = useState(false);
  const quantity = useMemo(() => (isNaN(from.current_quantity as any) ? from.quantity : from.current_quantity) || 0, [from])

  const handleConfirm = useCallback(async () => {
    try {

      if(!from || !to) {
        alert("Invalid Data");
        return;
      }

      setLoading(true);
      // Call API to exchange session
      // const url = "http://127.0.0.1:5001/hyper-karting/us-central1/exchangeSessions"
      const url = "https://us-central1-hyper-karting.cloudfunctions.net/exchangeSessions"

      await axios.post(url, {
        exchanges: [
          {
            orderId: from.order.id,
            updates: [
              {
                fromVariantId: from.variant_id,
                toVariantId: to.id,
                quantity 
              }
            ]
          }
        ]
      })

      await new Promise(resolve => setTimeout(resolve, 6000));

      // will have to wait for sometime to get the order updated in firebase by webhook
      if(onConfirm) await onConfirm();
    } catch (error) {
      console.log("Exchange session error", error);
    } finally {
      setLoading(false);
    }
  }, [onConfirm, from, to, quantity]);

  return (
    <div className="flex flex-col gap-1">
      <p className="my-4">
        Exchanging: 
        <br/>
        <b>{from.variant_title}</b> {'->'} <b>{to.title}</b> for <b>{from.order?.customer?.first_name} {from.order?.customer?.last_name}</b>
        <br/>
        Old price:<b>{from.price}</b> {'->'} New price:<b>{to.price}</b> for Quantity: <b>{from.quantity}</b> (Price difference: <b>{(+to.price - +from.price)*quantity}</b>)
        <br/>
        Are you sure you want to exchange this booking?
      </p>

      <hr className="mt-2" />
      <div className="flex justify-end gap-2 mt-5">
        <Button type="secondary" onClick={onCancel}>Cancel</Button>
        <Button onClick={handleConfirm} loading={loading}>Confirm</Button>
      </div>
    </div>
  )
}

export default SessionTable;
