import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { collection, getDocs, CollectionReference, doc, updateDoc } from "firebase/firestore";
import { useFirebase } from "../components/Firebase";
import { ICorporatesAndEvent, IModalConfig } from "../interfaces/CorporatesAndEvents";
import { DateTime } from "luxon";
import { sortByEventTime } from "../pages/corporate-events/sortByEventTime";

export interface ICorporatesAndEventsContext {
  corporateAndEventsData: {
    originalData: ICorporatesAndEvent[];
    displayToCustomerData: ICorporatesAndEvent[];
  };
  refresh: () => void;
  asyncDispatch: (action: Action) => Promise<void>;
}

// Reducer action types
const actionTypes = {
  LIST_ALL_EVENTS: "LIST_ALL_EVENTS",
  SET_MODAL_CONFIG: "SET_MODAL_CONFIG",
  CURRENTLY_SORT_BY: "CURRENTLY_SORT_BY",
  SELECTED_DATE: "SELECTED_DATE",
  UPDATE_FIELD: "UPDATE_FIELD",
} as const;

type Action =
  | {
      type: typeof actionTypes.LIST_ALL_EVENTS;
      payload: {
        originalData: ICorporatesAndEvent[];
        displayToCustomerData: ICorporatesAndEvent[];
      };
    }
  | { type: typeof actionTypes.SET_MODAL_CONFIG; payload: IModalConfig }
  | { type: typeof actionTypes.CURRENTLY_SORT_BY; payload: "ASCENDING" | "DESCENDING" }
  | { type: typeof actionTypes.SELECTED_DATE; payload: Date }
  | { type: typeof actionTypes.UPDATE_FIELD; payload: { id: string; fieldName: string; value: string } };

interface ICorporatesAndEventsState extends ICorporatesAndEventsContext {
  currentSortBy: "ASCENDING" | "DESCENDING";
  selectedDate: Date;
  modalConfig: IModalConfig;
  dispatch: React.Dispatch<Action>;
}

const initialState: ICorporatesAndEventsState = {
  corporateAndEventsData: {
    originalData: [],
    displayToCustomerData: [],
  },
  modalConfig: {
    modalOpeningConfig: {
      isModalOpen: false,
      modalOpenedFor: "CONDITION_UNKNOWN",
    },
    newModalData: null,
    selectedModalData: null,
  },
  currentSortBy: "ASCENDING",
  selectedDate: DateTime.now().setZone("Australia/Sydney").toJSDate(),
  refresh: () => {},
  dispatch: () => {},
  asyncDispatch: () => Promise.resolve(),
};

const CorporatesAndEventsContext = createContext<ICorporatesAndEventsState>(initialState);

const CorporatesAndEventsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const corporatesAndEventRef = useMemo(() => collection(db, "corporates_and_events") as CollectionReference<ICorporatesAndEvent>, [db]);

  const [state, dispatch] = useReducer((state: ICorporatesAndEventsState, action: Action): ICorporatesAndEventsState => {
    switch (action.type) {
      case actionTypes.SET_MODAL_CONFIG:
        return { ...state, modalConfig: action.payload };
      case actionTypes.LIST_ALL_EVENTS:
        return { ...state, corporateAndEventsData: action.payload };
      case actionTypes.CURRENTLY_SORT_BY:
        return { ...state, currentSortBy: action.payload };
      case actionTypes.SELECTED_DATE:
        return { ...state, selectedDate: action.payload };
      default:
        return state;
    }
  }, initialState);

  const fetchAndDispatchData = useCallback(async () => {
    const querySnapshot = await getDocs(corporatesAndEventRef);
    const data: ICorporatesAndEvent[] = [];

    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });

    let sortedData = [...data];

    sortedData = sortedData.filter((event) => DateTime.fromISO(event.eventDate).toISODate() === DateTime.fromJSDate(state.selectedDate).toISODate());

    if (state.currentSortBy) {
      sortedData = sortByEventTime(sortedData, state.currentSortBy);
    }

    dispatch({
      type: actionTypes.LIST_ALL_EVENTS,
      payload: {
        originalData: data,
        displayToCustomerData: sortedData,
      },
    });
  }, [corporatesAndEventRef, state.currentSortBy, state.selectedDate]);

  const refresh = async () => {
    await fetchAndDispatchData();
  };

  const asyncDispatch = useCallback(async (action: Action): Promise<void> => {
    try {
      if (action.type === actionTypes.UPDATE_FIELD) {
        const { id, fieldName, value } = action.payload;
        const docRef = doc(corporatesAndEventRef, id);
        await updateDoc(docRef, { [fieldName]: value });
        await fetchAndDispatchData();
      }
    } catch (error) {
      console.error("Error async dispatching:",action, error);
      return Promise.reject(error);
    }

    dispatch(action);
  }, [corporatesAndEventRef, fetchAndDispatchData]);

  useEffect(() => {
    fetchAndDispatchData();
  }, [fetchAndDispatchData]);

  return <CorporatesAndEventsContext.Provider value={{ ...state, refresh, dispatch, asyncDispatch }}>{children}</CorporatesAndEventsContext.Provider>;
};

const useCorporatesAndEvents = () => {
  const context = useContext(CorporatesAndEventsContext);

  if (!context) {
    throw new Error("useCorporatesAndEvents must be used within a CorporatesAndEventsProvider");
  }

  return context;
};

export { CorporatesAndEventsProvider, useCorporatesAndEvents };
