import { DragEvent, PropsWithChildren, useCallback, useState } from "react";

const DragHandle = ({ children, extraData, id }: IDragHandleProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = useCallback((e: DragEvent<HTMLDivElement>) => {
    setIsDragging(true);
    // You can set custom data to be transferred
    e.dataTransfer.setData("text/plain", JSON.stringify({ id, extraData }));
  }, [id, extraData]);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      style={{
        cursor: "grab",
        opacity: isDragging ? 0.5 : 1,
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <div style={{ cursor: "grab" }}>⋮⋮</div>
      {children && children}
    </div>
  );
};

interface IDragHandleProps extends PropsWithChildren {
    id: string | number;
    extraData?: Record<string, any>;
}

export default DragHandle;