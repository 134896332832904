import { ICustomer, IOrder, IOrderCustomer, OrderFinancialStatus } from "shopify-api-node";
import {
  ACTIVE_SUBSCRIBER,
  BREAK_BOOKING_NAME,
  SPECIAL_TIMES_WITH_BREAKS,
} from "../../constants/Dashboard";
import { ILineItemWithOrder, ISessionWithBookings } from "../../interfaces/Session";

export const getStatusClass = (status: OrderFinancialStatus): string => {
  switch (status) {
    case "paid":
      return "rounded-full text-center text-white bg-green-600 border border-green-600 px-2.5 py-1 w-max hidden";
    case "refunded":
      return "rounded-full text-center text-white bg-red-600 border border-red-600 px-2.5 py-1 w-max";
    case "partially_refunded":
      // Hide partrefund button till we find other solution to display on ipad
      return "rounded-full text-center bg-[#fd8f00] text-white bg-orange-600 border border-[#fd8f00] px-2.5 py-1 w-max";
    case "partially_paid":
      return "rounded-full text-center text-white bg-red-800 border border-red-800 px-2.5 py-1 w-max";
    default:
      return "rounded-full text-center bg-blue text-white bg-orange-600 border border-orange-600 px-2.5 py-1";
  }
};

export const getFinancialStatus = (order: IOrder) => {
  switch (order.financial_status) {
    case "paid":
      return "Paid";
    case "refunded":
      return "Refunded";
    case "partially_refunded":
      return "Part Refund";
    case "partially_paid":
      return "Part Paid";
    default:
      return order.financial_status;
  }
};

export const mainTrackSeatsSoldColour = (numSeats: number) => {
  switch (true) {
    case numSeats === 0:
      return "#ffffff";
    case numSeats <= 4:
      return "#63ff00";
    case numSeats <= 8:
      return "#FFFF00";
    case numSeats <= 12:
      return "#ffb201";
    case numSeats <= 14:
      return "#ff00ff";
    case numSeats > 14:
      return "#ff0000";
  }
};

export const miniTrackSeatsSoldColour = (numSeats: number) => {
  switch (true) {
    case numSeats >= 0 && numSeats <= 2:
      return "#63ff00";
    case numSeats >= 3 && numSeats <= 4:
      return "#FFFF00";
    case numSeats >= 5 && numSeats <= 6:
      return "#ff0000";
    case numSeats >= 7:
      return "#ff00ff";
  }
};

export const vrSeatsSoldColour = (numSeats: number) => {
  switch (true) {
    case numSeats > 0 && numSeats <= 2:
      return "#63ff00";
    case numSeats >= 3 && numSeats <= 4:
      return "#FFFF00";
    case numSeats >= 5 && numSeats <= 6:
      return "#ffb201";
    case numSeats >= 7:
      return "#ff0000";
  }
};

export const getDriverCount = (booking: ILineItemWithOrder): number => {
  if(booking.current_quantity !== undefined){
    return booking.current_quantity;
  }
  let driverCount = booking.quantity;
  const lineItemId = booking.id;
  const parentOrder = booking.order;
  const isBookingWithBreak =
    booking.order.customer?.first_name
      ?.toLowerCase()
      .includes(BREAK_BOOKING_NAME.toLowerCase()) &&
    SPECIAL_TIMES_WITH_BREAKS.some((time) =>
      booking.variant_title.includes(time)
    );

  if (isBookingWithBreak) {
    driverCount = 0;
  } else {
    // Remove refunded line items
    parentOrder.refunds.forEach((refund) => {
      const applicableLineItems = refund.refund_line_items.filter(
        (re) => re.line_item_id === lineItemId
      );
      applicableLineItems.forEach((li) => {
        driverCount = driverCount - li.quantity;
      });
    });
  }

  return driverCount;
};

export const isBookedByMember = (customer?: ICustomer | IOrderCustomer)=> customer && customer.tags && customer.tags.includes(ACTIVE_SUBSCRIBER);

export const getSameSessionBookings = (session:ISessionWithBookings, booking: ILineItemWithOrder)=> session.bookings.filter(b=>b.order.customer?.id && booking.order.customer?.id && (b.order.customer.id === booking.order.customer?.id));

// Helper function to convert hex to RGB
const hexToRGB = (hex: string): [number, number, number] => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

// Helper function to convert RGB to hex
const rgbToHex = (r: number, g: number, b: number): string => {
  const toHex = (n: number) => {
    const hex = Math.round(n).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const getGradientColor = (startColor: string, endColor: string, percentage: number): string => {
  // Ensure percentage is between 0 and 100
  const normalizedPercentage = Math.max(0, Math.min(100, percentage)) / 100;
  
  // Convert hex colors to RGB
  const [startR, startG, startB] = hexToRGB(startColor);
  const [endR, endG, endB] = hexToRGB(endColor);
  
  // Interpolate between the colors
  const r = startR + (endR - startR) * normalizedPercentage;
  const g = startG + (endG - startG) * normalizedPercentage;
  const b = startB + (endB - startB) * normalizedPercentage;
  
  return rgbToHex(r, g, b);
};