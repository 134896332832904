import { ICorporatesAndEvent } from "../../interfaces/CorporatesAndEvents";

export const validateCorporateAndEvent = (event: Partial<ICorporatesAndEvent>): string | null => {
  const { name, eventType, activities, eventDate, numberOfAttendees, eventTime, note, catering } = event;

  if (!name) return "Please make sure Event Name input is not empty.";
  if (!eventType) return "Please make sure Event Type selected.";
  if (!eventDate) return "Please make sure Event date is picked";
  if (!eventTime) return "Please make sure Event time is selected.";
  if (typeof numberOfAttendees === "undefined") return "Please make sure number of people is a validate number.";
  if (!note) return "Please make sure note input is empty.";
  if (!activities || !activities.length) return "Please make sure activities are selected.";
  if (typeof catering === "undefined") return "Please make Catering is selected.";

  return null;
};
