import { ICorporatesAndEvent } from "../../interfaces/CorporatesAndEvents";

export const sortByEventTime = (arr: ICorporatesAndEvent[], sortBy: "ASCENDING" | "DESCENDING") => {
  return arr.slice().sort((a, b) => {
    // Use .slice() to create a copy of the array
    const getTimeInMinutes = (eventTime: string) => {
      const [start, end] = eventTime.split(" - ").map((time) => {
        const [hour, minute] = time.split(":").map(Number);
        return hour * 60 + minute;
      });
      console.log("ended time", end);
      return start;
    };

    // Get the comparison result
    const comparison = getTimeInMinutes(a.eventTime) - getTimeInMinutes(b.eventTime);

    // Adjust comparison based on sort order
    return sortBy === "ASCENDING" ? comparison : -comparison;
  });
};
