import React, { useCallback, useMemo } from 'react'
import DropDown from '../../components/Shared/DropDown'
import { TrackConfigurations } from '../../constants'
import ComboButton from '../../components/Shared/ComboButton'

const TrackConfigurationDropdown = ({ index, onChange, selected }: { index: number, onChange: (value: string) => void, selected: string }) => {
    const options = useMemo(()=>TrackConfigurations.map(track => ({
        key: track.uuid,
        label: track.name,
    })), [])

    const handleChange = useCallback((value: string) => {
        onChange && onChange(value)
    }, [onChange])
    return (
        <div style={{ position: 'relative', zIndex: 999 - index }}>
            <DropDown
                items={options}
                defaultValue="Select Track"
                onSelect={handleChange}
                selected={selected}
            />
        </div>
    )
}

export const TrackConfigurationDropdownWithSwitch = ({ index, onChange, selected }: { index: number, onChange: (value: string) => void, selected: string }) => {
    const dropdownOptions = Array.from(new Set(TrackConfigurations.map(config => config.name.replace("Anti-clockwise", "").replace("Anti-Clockwise", "").replace("Clockwise", "").trim()))).map(label => ({ key: label, label }))
    const selectedConfig = useMemo(() => TrackConfigurations.find(config => config.uuid === selected), [selected])
    const selectedInDropDown = useMemo(() => selectedConfig?.name.replace("Anti-clockwise", "").replace("Anti-Clockwise", "").replace("Clockwise", "").trim(), [selectedConfig])
    const showComboButton = useMemo(() => selectedInDropDown && selectedConfig?.name.toLowerCase().includes("clockwise"), [selectedInDropDown, selectedConfig])
    const selectedInComboBox = useMemo(() => showComboButton ? (selectedConfig?.name?.toLowerCase()?.includes("anti-clockwise") ? "LEFT" : "RIGHT") : null, [selectedConfig, showComboButton])
    const clockwiseExists = useMemo(()=>TrackConfigurations.find(config => selectedInDropDown && config.name.toLowerCase().includes(selectedInDropDown.toLowerCase()) && config.name.toLowerCase().includes("clockwise")), [selectedInDropDown])
    const antiClockwiseExists = useMemo(()=>TrackConfigurations.find(config => selectedInDropDown && config.name.toLowerCase().includes(selectedInDropDown.toLowerCase()) && config.name.toLowerCase().includes("anti-clockwise")), [selectedInDropDown])

    const handleDropdownChange = useCallback((value: string) => {
        const newVal = TrackConfigurations.find(config => {
            const name  = config.name.toLocaleLowerCase();
            return value && name.includes(value.toLowerCase()) && (!value.toLocaleLowerCase().includes("clockwise") || name.includes((selectedInComboBox === "LEFT" ? "anti-clockwise" : "clockwise").toLowerCase()))
        })
        if(newVal){
            onChange(newVal.uuid)
        }
    }, [selectedInComboBox, onChange])

    const handleSwitchChange = useCallback((value: string) => {
        if(selectedInDropDown){
            const newVal = TrackConfigurations.filter(config => config.name.includes(selectedInDropDown)).find(config => config.name.toLowerCase().includes(value === "LEFT" ? "anti-clockwise" : "clockwise"))?.uuid
            if(newVal){
                onChange(newVal)
            }
        }
    }, [selectedInDropDown, onChange])

    return (
        <div className='flex items-center gap-2 justify-center h-full' style={{ position: 'relative', zIndex: 999 - index }}>
            <DropDown
                items={dropdownOptions}
                onSelect={handleDropdownChange}
                selected={selectedInDropDown}
                className='h-auto min-w-[245px] justify-between'
                renderItem={(item) => item.label.replace(",", "").trim()}
            />
            {showComboButton && <ComboButton leftButtonName={clockwiseExists && <LeftCircularIcon />} rightButtonName={antiClockwiseExists && <RightCircularIcon />} selected={selectedConfig?.name?.toLowerCase()?.includes("anti-clockwise") ? "LEFT" : "RIGHT"} onChange={handleSwitchChange} className='h-auto' />}
        </div>
    )
}

const RightCircularIcon = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 16 16" 
        stroke="currentColor"
        strokeWidth={1.5}
        className="size-6 w-6 h-6"
    >
        <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="m11.25 5.25h3m0 3.5c0 2.5-2.79822 5.5-6.25 5.5s-6.25-2.79822-6.25-6.25 2.79822-6.25 6.25-6.25c3.75 0 6.25 3.5 6.25 3.5v-3.5"
        />
    </svg>
)

const LeftCircularIcon = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 16 16" 
        stroke="currentColor"
        strokeWidth={1.5}
        className="size-6 w-6 h-6"
    >
        <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="m4.75 5.25h-3m0 3.5c0 2.5 2.79822 5.5 6.25 5.5s6.25-2.79822 6.25-6.25-2.79822-6.25-6.25-6.25c-3.75 0-6.25 3.5-6.25 3.5v-3.5"
        />
    </svg>
)

export default TrackConfigurationDropdown