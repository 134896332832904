import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DateTime } from "luxon";
import Button from "../../components/Shared/Button";
import Checkbox from "../../components/Shared/Checkbox";
import DateInput from "../../components/Shared/DateInput";
import Label from "../../components/Shared/Label";
import NumberInput from "../../components/Shared/NumberInput";
import TextAreaInput from "../../components/Shared/TextAreaInput";
import TextInput from "../../components/Shared/TextInput";
import TimeInput from "../../components/Shared/TimeInput";
import { ICorporatesAndEvent, IModalConfig, TActivity } from "../../interfaces/CorporatesAndEvents";
import { useCorporatesAndEvents } from "../../context/CorporatesAndEvents";
import DropDown from "../../components/Shared/DropDown";
import { validateCorporateAndEvent } from "./validateCorporatePayload";

const CorporatesAndEventsModal: React.FC = () => {
  const { modalConfig, dispatch, currentSortBy } = useCorporatesAndEvents();
  const isModalOpened: boolean = useMemo(() => modalConfig.modalOpeningConfig.isModalOpen, [modalConfig]);
  const [errorText, setErrorText] = useState<{ errorMessage: string } | null>(null);
  const [sendingRequestFor, setSendingRequestFor] = useState<"POST" | "PUT" | "DELETE" | undefined>(undefined);

  const handleCloseModal = () => {
    dispatch({
      type: "SET_MODAL_CONFIG",
      payload: {
        ...modalConfig,
        modalOpeningConfig: {
          ...modalConfig.modalOpeningConfig,
          isModalOpen: false,
        },
      },
    });
  };

  const isItNewModal = useCallback((modal: IModalConfig) => modal.modalOpeningConfig.isModalOpen && modal.modalOpeningConfig.modalOpenedFor === "CREATE_NEW_EVENT", []);

  const handleChangeDate = (date: Date) => {
    // Convert the date to Australia's time zone
    const localizedDate = DateTime.fromJSDate(date, { zone: "Australia/Sydney" }).toISO();

    if (isItNewModal(modalConfig)) {
      dispatch({
        type: "SET_MODAL_CONFIG",
        payload: {
          ...modalConfig,
          newModalData: {
            ...modalConfig.newModalData,
            eventDate: localizedDate,
          } as ICorporatesAndEvent,
        },
      });
    } else {
      dispatch({
        type: "SET_MODAL_CONFIG",
        payload: {
          ...modalConfig,
          selectedModalData: {
            ...modalConfig.selectedModalData,
            eventDate: localizedDate,
          } as ICorporatesAndEvent,
        },
      });
    }
  };

  const handleChangeTime = (time: string) => {
    if (isItNewModal(modalConfig)) {
      dispatch({
        type: "SET_MODAL_CONFIG",
        payload: {
          ...modalConfig,
          newModalData: {
            ...modalConfig.newModalData,
            eventTime: time,
          } as ICorporatesAndEvent,
        },
      });
    } else {
      dispatch({
        type: "SET_MODAL_CONFIG",
        payload: {
          ...modalConfig,
          selectedModalData: {
            ...modalConfig.selectedModalData,
            eventTime: time,
          } as ICorporatesAndEvent,
        },
      });
    }
  };

  const handleChangeValue = (key: keyof ICorporatesAndEvent, value: any) => {
    if (isItNewModal(modalConfig) && modalConfig.newModalData) {
      dispatch({
        type: "SET_MODAL_CONFIG",
        payload: {
          ...modalConfig,
          newModalData: {
            ...modalConfig.newModalData,
            [key]: value,
          } as ICorporatesAndEvent,
        },
      });
    } else {
      dispatch({
        type: "SET_MODAL_CONFIG",
        payload: {
          ...modalConfig,
          selectedModalData: {
            ...modalConfig.selectedModalData,
            [key]: value,
          } as ICorporatesAndEvent,
        },
      });
    }
  };

  const handleChangeActivity = (activity: TActivity) => {
    if (isItNewModal(modalConfig)) {
      const selectedActivitiesList = modalConfig?.newModalData?.activities || [];
      const allActivities = ["Main Track", "Mini Track", "VR", "Junior Track", "Intermediate Track", "Venue Hire"];

      if (activity === "Select All") {
        if (selectedActivitiesList.includes("Select All")) {
          dispatch({
            type: "SET_MODAL_CONFIG",
            payload: {
              ...modalConfig,
              newModalData: {
                ...modalConfig.newModalData,
                activities: [],
              } as ICorporatesAndEvent,
            },
          });
        } else {
          dispatch({
            type: "SET_MODAL_CONFIG",
            payload: {
              ...modalConfig,
              newModalData: {
                ...modalConfig.newModalData,
                activities: [...allActivities, "Select All"],
              } as ICorporatesAndEvent,
            },
          });
        }
      } else {
        const updatedActivities = selectedActivitiesList.includes(activity) ? selectedActivitiesList.filter((act) => act !== activity) : [...selectedActivitiesList, activity];

        const isSelectAllChecked = allActivities.every((act) => updatedActivities?.includes(act as TActivity));

        dispatch({
          type: "SET_MODAL_CONFIG",
          payload: {
            ...modalConfig,
            newModalData: {
              ...modalConfig.newModalData,
              activities: isSelectAllChecked ? [...updatedActivities, "Select All"] : updatedActivities.filter((act) => act !== "Select All"),
            } as ICorporatesAndEvent,
          },
        });
      }
    } else {
      const selectedActivitiesList = modalConfig?.selectedModalData?.activities || [];
      const allActivities = ["Main Track", "Mini Track", "VR", "Junior Track", "Intermediate Track", "Venue Hire"];

      if (activity === "Select All") {
        if (selectedActivitiesList.includes("Select All")) {
          console.log("Unselect all should happen");
          dispatch({
            type: "SET_MODAL_CONFIG",
            payload: {
              ...modalConfig,
              selectedModalData: {
                ...modalConfig.selectedModalData,
                activities: [],
              } as ICorporatesAndEvent,
            },
          });
        } else {
          dispatch({
            type: "SET_MODAL_CONFIG",
            payload: {
              ...modalConfig,
              selectedModalData: {
                ...modalConfig.selectedModalData,
                activities: [...allActivities, "Select All"],
              } as ICorporatesAndEvent,
            },
          });
        }
      } else {
        const updatedActivities = selectedActivitiesList?.includes(activity) ? selectedActivitiesList?.filter((act) => act !== activity) : [...selectedActivitiesList, activity];

        const isSelectAllChecked = allActivities?.every((act) => updatedActivities?.includes(act as TActivity));

        dispatch({
          type: "SET_MODAL_CONFIG",
          payload: {
            ...modalConfig,
            selectedModalData: {
              ...modalConfig.selectedModalData,
              activities: isSelectAllChecked ? [...updatedActivities, "Select All"] : updatedActivities.filter((act) => act !== "Select All"),
            } as ICorporatesAndEvent,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (errorText) {
      setTimeout(() => {
        setErrorText(null);
      }, 3000);
    }
  }, [errorText]);

  const handleSaveChanges = async (data: ICorporatesAndEvent, requestMethod: "POST" | "PUT" | "DELETE") => {
    if (requestMethod === "POST") {
      const isDataValidate = validateCorporateAndEvent(data);

      if (isDataValidate) {
        setErrorText({ errorMessage: isDataValidate });
        return;
      }
    }

    try {
      const modalDate = modalConfig?.newModalData?.eventDate || modalConfig?.selectedModalData?.eventDate;

      if (modalDate) {
        dispatch({ type: "SELECTED_DATE", payload: DateTime.fromJSDate(new Date(modalDate as string), { zone: "Australia/Sydney" }).toJSDate() });
      }

      setSendingRequestFor(requestMethod);
      const payload: ICorporatesAndEvent = data;
      const response = await fetch(`https://us-central1-hyper-karting.cloudfunctions.net/corporateAndEvent`, {
        method: requestMethod,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...payload }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSendingRequestFor(undefined);
        const { documents } = responseData;

        if (documents?.length > 0) {
          let sortedData: ICorporatesAndEvent[] = [...documents];

          if (modalDate) {
            sortedData = sortedData.filter((event) => DateTime.fromISO(event.eventDate).toISODate() === DateTime.fromJSDate(new Date(modalDate as string)).toISODate());
          }

          sortedData.sort((a, b) => {
            // Use DateTime for date comparison
            const dateA = DateTime.fromISO(a.eventDate);
            const dateB = DateTime.fromISO(b.eventDate);

            // Compare dates first
            const dateComparison = currentSortBy === "ASCENDING" ? dateA.toMillis() - dateB.toMillis() : dateB.toMillis() - dateA.toMillis();

            if (dateComparison !== 0) {
              return dateComparison; // Return if dates are different
            }

            // If dates are the same, compare times
            const [hoursA, minutesA] = a.eventTime.split(" - ")[0].split(":").map(Number);
            const [hoursB, minutesB] = b.eventTime.split(" - ")[0].split(":").map(Number);

            const totalMinutesA = hoursA * 60 + minutesA;
            const totalMinutesB = hoursB * 60 + minutesB;

            // Sort by time if the dates are the same
            return currentSortBy === "ASCENDING" ? totalMinutesA - totalMinutesB : totalMinutesB - totalMinutesA;
          });

          dispatch({
            type: "LIST_ALL_EVENTS",
            payload: {
              originalData: documents,
              displayToCustomerData: sortedData,
            },
          });
        }
        handleCloseModal();
      } else {
        setSendingRequestFor(undefined);
        handleCloseModal();
        console.warn("Unexpected response status:", response.status, await response.text());
      }
    } catch (err) {
      setSendingRequestFor(undefined);
      handleCloseModal();
      console.error({
        errorMessage: "Error occurred while saving changes",
        errorInfo: err,
      });
    }
  };

  return (
    <Transition appear show={isModalOpened} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        {/* Modal Panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <Dialog.Panel className="bg-white rounded-lg shadow-lg w-full max-w-4xl p-6">
              <div className="mt-8 h-full">
                {errorText && <p className="text-center text-[red]">{errorText.errorMessage}</p>}
                <div className="flex flex-col gap-5 h-[calc(100%-110px)] overflow-auto">
                  <TextInput
                    label="Event Name"
                    required
                    onChange={(value) => handleChangeValue("name", value)}
                    value={isItNewModal(modalConfig) && modalConfig?.newModalData ? modalConfig?.newModalData?.name : modalConfig?.selectedModalData?.name}
                    placeholder="Add event name"
                  />
                  <DropDown
                    items={[
                      {
                        key: "Corporate",
                        label: "Corporate",
                      },
                      {
                        key: "Birthday Party",
                        label: "Birthday Party",
                      },
                      {
                        key: "Concession Group",
                        label: "Concession Group",
                      },
                      {
                        key: "Bucks/Hens",
                        label: "Bucks/Hens",
                      },
                      {
                        key: "Other",
                        label: "Other",
                      },
                    ]}
                    className="!w-full justify-between !bg-[#ffffff] !text-[#000000] border [&>svg]:fill-[#000000] !font-normal"
                    selected={
                      isItNewModal(modalConfig) && modalConfig.newModalData
                        ? modalConfig.newModalData?.eventType
                        : !isItNewModal(modalConfig) && modalConfig.selectedModalData
                        ? modalConfig.selectedModalData?.eventType
                        : "Select Event Type"
                    }
                    onSelect={(val) => handleChangeValue("eventType", val)}
                    defaultValue={"Select Event Type"}
                  />
                  <div className="flex gap-4 items-end">
                    <DateInput
                      label="Date and Time"
                      required
                      onChange={handleChangeDate}
                      value={
                        isItNewModal(modalConfig) && modalConfig.newModalData
                          ? new Date(modalConfig?.newModalData?.eventDate)
                          : !isItNewModal(modalConfig) && modalConfig.selectedModalData
                          ? new Date(modalConfig.selectedModalData?.eventDate)
                          : new Date()
                      }
                    />
                    <TimeInput
                      label="Time"
                      onChange={handleChangeTime}
                      value={modalConfig?.selectedModalData?.eventTime}
                      placeholder="e.g 13:00 - 14:30"
                      isTimeStartAndEnd={true}
                    />
                  </div>
                  <div>
                    <Label showRequiredSymbol>Activities:</Label>
                    <div className="flex gap-4">
                      {["Main Track", "Mini Track", "VR", "Junior Track", "Intermediate Track", "Venue Hire"].map((activity) => {
                        const isActivityChecked: boolean =
                          isItNewModal(modalConfig) && modalConfig.newModalData
                            ? modalConfig?.newModalData?.activities?.includes(activity as TActivity)
                            : !isItNewModal(modalConfig) && modalConfig.selectedModalData
                            ? modalConfig.selectedModalData?.activities.includes(activity as TActivity)
                            : false;
                        return (
                          <div className="flex gap-1 items-center hover:cursor-pointer" key={activity} onClick={() => handleChangeActivity(activity as TActivity)}>
                            <span className="w-[16px] h-[16px] border-[#9D62FE] border border-solid rounded-full overflow-hidden flex justify-center items-center">
                              {isActivityChecked && <span className="w-[12px] h-[12px] rounded-full" style={{ background: "#9D62FE" }}></span>}
                            </span>
                            <span className="text-sm">{activity}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    <Label showRequiredSymbol>Catering:</Label>
                    <div className="flex gap-4">
                      <Checkbox
                        label="Yes"
                        checked={
                          isItNewModal(modalConfig) && modalConfig.newModalData ? modalConfig.newModalData.catering === true : modalConfig.selectedModalData?.catering === true
                        }
                        onChange={() => handleChangeValue("catering", true)}
                      />
                      <Checkbox
                        label="No"
                        checked={
                          isItNewModal(modalConfig) && modalConfig.newModalData ? modalConfig.newModalData.catering === false : modalConfig.selectedModalData?.catering === false
                        }
                        onChange={() => handleChangeValue("catering", false)}
                      />
                    </div>
                  </div>

                  <NumberInput
                    label="Number of People:"
                    onChange={(value) => handleChangeValue("numberOfAttendees", value)}
                    value={
                      isItNewModal(modalConfig) && modalConfig?.newModalData ? modalConfig?.newModalData?.numberOfAttendees : modalConfig?.selectedModalData?.numberOfAttendees
                    }
                    hideArrows
                    defaultEmpty
                  />
                  <TextInput
                    label="Shopify Order URL"
                    onChange={(value) => handleChangeValue("shopifyOrderUrl", value)}
                    value={isItNewModal(modalConfig) && modalConfig?.newModalData ? modalConfig?.newModalData?.shopifyOrderUrl : modalConfig?.selectedModalData?.shopifyOrderUrl}
                    placeholder="Add Shopify Order URL"
                  />
                  <TextAreaInput
                    label="Note:"
                    onChange={(value) => handleChangeValue("note", value)}
                    placeholder="Add note"
                    value={isItNewModal(modalConfig) ? modalConfig?.newModalData?.note ?? "" : modalConfig?.selectedModalData?.note ?? ""}
                  />
                </div>
                <div className="mt-3 flex gap-4 justify-end">
                  <Button onClick={handleCloseModal} type="secondary">
                    Cancel
                  </Button>
                  {!isItNewModal(modalConfig) && modalConfig?.selectedModalData && (
                    <Button
                      onClick={() => handleSaveChanges(modalConfig.selectedModalData as ICorporatesAndEvent, "DELETE")}
                      type="secondary"
                      className="hover:!bg-[red] hover:!text-[white] hover:!border-[red]"
                    >
                      {sendingRequestFor === "DELETE" ? "Deleting..." : "Delete Event"}
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      isItNewModal(modalConfig)
                        ? handleSaveChanges(modalConfig.newModalData as ICorporatesAndEvent, "POST")
                        : handleSaveChanges(modalConfig.selectedModalData as ICorporatesAndEvent, "PUT")
                    }
                  >
                    {sendingRequestFor === "POST" ? "Creating..." : sendingRequestFor === "PUT" ? "Saving..." : isItNewModal(modalConfig) ? "Create Event" : "Save Changes"}
                  </Button>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CorporatesAndEventsModal;
