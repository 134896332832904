import React, { ReactNode, useCallback, useEffect, useState } from "react";

export type TComboButton = "LEFT" | "RIGHT" | "All";

interface Props {
  allOptions?: string;
  leftButtonName?: ReactNode | string;
  rightButtonName?: ReactNode | string;
  disableRightButton?: boolean;
  hideRightButton?: boolean;
  onChange?: (selected: TComboButton) => void;
  selected?: TComboButton;
  className?: string;
}
export default function ComboButton({ leftButtonName, rightButtonName, onChange, disableRightButton, hideRightButton, allOptions, selected: selectedProp, className = "" }: Props) {
  const [selected, setSelected] = useState<TComboButton>(() => (allOptions && allOptions === "All" ? allOptions : "LEFT"));

  const handleSelect = useCallback(
    (val: TComboButton) => () => {
      setSelected(val);
      onChange && onChange(val);
    },
    [onChange]
  );

  useEffect(() => {
    if (selectedProp) {
      setSelected(selectedProp);
    }
  }, [selectedProp]);

  return (
    <div className={`flex items-center justify-between rounded-full h-full bg-gray-100 hidden xl:flex ${className}`}>
      {allOptions && allOptions === "All" && <Btn name={allOptions} selected={selected === "All"} toggleSelect={handleSelect("All")} />}
      {leftButtonName && <Btn name={leftButtonName} selected={selected === "LEFT"} toggleSelect={handleSelect("LEFT")} />}
      {(!!!hideRightButton && rightButtonName) && <Btn name={rightButtonName} selected={selected === "RIGHT"} toggleSelect={handleSelect("RIGHT")} disable={disableRightButton} />}
    </div>
  );
}

export function Btn({ selected, name, toggleSelect, disable }: { selected: boolean; name: ReactNode | string; toggleSelect: () => void; disable?: boolean }) {
  return (
    <div className={`h-full rounded-full ${selected ? "hk-gradient-bg p-1" : ""} ${disable ? "pointer-events-none" : "cursor-pointer"}`} onClick={toggleSelect}>
      <div className={`flex justify-center items-center h-full px-4 py-1 font-bold text-sm rounded-full ${selected ? "bg-gray-800 text-white" : "bg-gray-100 text-gray-600"}`}>
        {name}
      </div>
    </div>
  );
}
