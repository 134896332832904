import React, { useMemo } from 'react'
import { ILineItemWithOrder } from '../../interfaces/Session'
import { Tooltip } from '@material-tailwind/react'
import { getOrderOccasion } from '../../utilities';
import { GiftIcon, IdentificationIcon, UserGroupIcon, ChartPieIcon, ExclamationCircleIcon } from "@heroicons/react/solid";

const occasionIcon = {
    PERSONAL: IdentificationIcon,
    PARTY: GiftIcon,
    GROUP: UserGroupIcon,
    CORPORATE: ChartPieIcon,
}

const occasionColors = {
    PERSONAL: '#FF6B6B',
    GROUP: '#4ECDC4',
    CORPORATE: '#1E90FF',
    PARTY: '#FFD700',
    OTHER: '#A9A9A9',
}

const Occasion = ({ booking }: { booking: ILineItemWithOrder }) => {
    const occasion = useMemo(() => getOrderOccasion(booking.order)?.toUpperCase(), [booking]);

    const Icon = useMemo(() => {
        if(!occasion) return null;
        return Object.keys(occasionIcon).includes(occasion) ? occasionIcon[occasion as keyof typeof occasionIcon] : ExclamationCircleIcon;
    }, [occasion])

    const iconColor = useMemo(() => {
        if (!occasion) return occasionColors.OTHER;
        return Object.keys(occasionColors).includes(occasion) ? occasionColors[occasion as keyof typeof occasionColors] : occasionColors.OTHER;
    }, [occasion]);

    return (
        <div key={booking.id} className={`border-b flex flex-row items-center`}>
            <Tooltip content={occasion}>
                {Icon ? <Icon fill={iconColor} width={"1.5rem"} height={"1.5rem"} /> : <p>&#8203;</p>}
            </Tooltip>
        </div>
    )
}

export default Occasion