import { FC, ReactNode } from "react";

interface Props {
  children?: ReactNode | string;
  showRequiredSymbol?: boolean;
  color?: string;
  className?: string;
}
const Label: FC<Props> = ({ children, showRequiredSymbol = false, color = "text-[#2F1160]", className = "" }) => {
  return (
    <label className={`${color} text-xs not-italic font-bold leading-[normal] font-[Montserrat] mb-2 ${className}`}>
      {children} {showRequiredSymbol && <span className="text-red-600">*</span>}
    </label>
  );
};

export default Label;
