import React, { useCallback, useMemo } from "react";
import Calender from "../../components/Shared/Calender";
import DropDown from "../../components/Shared/DropDown";
import SearchBar from "../../components/Shared/SearchBar";
import { RefreshIcon } from "@heroicons/react/solid";
import { useBookingSession } from "../../context/BookingSession";
import Button from "../../components/Shared/Button";
import { usePricechanger } from "../../context/PriceChanger";
import { useModal } from "../../context/Modal";
import { getDatetimeFromSku, getTrackDisplayName } from "../../utilities";
import { TrackConfigurationDropdownWithSwitch } from "./TrackConfigurationDropdown";
import { useDriverRegistration } from "../../context/DriverRegistration";

interface Props {
  selectedDate: Date;
  changeView: (val: string) => void;
  changeSelectedDate: (val: Date, type?: "product" | "compareProduct") => void;
}
function Header({ selectedDate, changeView, changeSelectedDate }: Props) {
  const { setModalData, setshowModal } = useModal();
  const { refreshSessions, applySearchFilter, track, product, filteredSessions } = useBookingSession();
  const { isUpdating, isUpdateButtonActive, selectedTrackConfig, view, rfSessions, updateSelectedTrackConfig } = usePricechanger();
  const { bookings } = useDriverRegistration();

  const trackConfigChangeableSessions = useMemo(() => {
    return filteredSessions.filter((session) => {
      const dt = getDatetimeFromSku(session.sku, view);
      if (dt) {
        const scheduleSession = (rfSessions?.sessions || []).find((s) => s.start_time_key.includes(dt.toFormat("yyyy-MM-dd HH:mm")));
        if (scheduleSession?.status === "not_started") {
          const orderIds = session.bookings.map((booking) => booking.order.id);
          return orderIds.find((orderId) => bookings?.[orderId]?.registrations?.find((r) => r.sku === session.sku));
        }
      }
      return false;
    });
  }, [filteredSessions, rfSessions, bookings, view]);

  const productHandle = useMemo(() => product?.handle, [product]);

  const onUpdateBtnClick = useCallback(() => {
    if (product?.id) {
      setModalData({
        id: "PRICE_CHANGER_CONFIRMATION",
        onClose: () => setshowModal(false),
        title: "Updating Sessions",
        content: (
          <ConformationModalContent
            onCancel={() => setshowModal(false)}
            onUpdate={() => {
              if (product?.id) {
                setshowModal(false);
              }
            }}
          />
        ),
      });
      setshowModal(true);
    }
  }, [product, setModalData, setshowModal]);

  const handleDateChange = useCallback(
    (val: Date) => {
      changeSelectedDate(val, "product");
    },
    [changeSelectedDate]
  );

  const handleTrackConfigChange = useCallback(
    (val: string) => {
      updateSelectedTrackConfig(val, trackConfigChangeableSessions);
    },
    [updateSelectedTrackConfig, trackConfigChangeableSessions]
  );

  return (
    <div className="flex min-w-3 w-[100%] items-center gap-4 h-12">
      <div className="w-1/3 h-full">
        <SearchBar onChange={applySearchFilter} />
      </div>

      <Calender onDateChange={handleDateChange} value={selectedDate} copyText={productHandle} copyTooltip="Copy Product SKU" persistValue />

      <div className="flex-1"></div>

      <div className="flex items-center gap-2">
        <div className="min-w-[250px]">
          <TrackConfigurationDropdownWithSwitch index={0} onChange={handleTrackConfigChange} selected={selectedTrackConfig} />
        </div>
      </div>

      <Button
        disabled={isUpdating || !isUpdateButtonActive}
        loading={isUpdating}
        onClick={onUpdateBtnClick}
        className={`@apply bg-[linear-gradient(282.87deg,#d32efe_14.09%,#05fffa_103.26%)] text-white ${isUpdateButtonActive ? "" : "opacity-50"}`}
        withCustomColor
      >
        Update
      </Button>

      <DropDown
        items={[
          {
            key: "MAIN_TRACK",
            label: getTrackDisplayName("MAIN_TRACK"),
          },
          {
            key: "MINI_TRACK",
            label: getTrackDisplayName("MINI_TRACK"),
          },
          {
            key: "VR",
            label: getTrackDisplayName("VR"),
          },
          {
            key: "JUNIOR_TRACK",
            label: getTrackDisplayName("JUNIOR_TRACK"),
          },
          {
            key: "INTERMEDIATE_TRACK",
            label: getTrackDisplayName("INTERMEDIATE_TRACK"),
          },
        ]}
        selected={track}
        onSelect={changeView}
      />

      <div className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3" onClick={refreshSessions}>
        <RefreshIcon className="text-white w-6" />
      </div>
    </div>
  );
}

const ConformationModalContent = ({ onCancel, onUpdate }: { onCancel: () => void; onUpdate: () => void }) => {
  const { refreshSessions, product } = useBookingSession();
  const { updateItems, isUpdating, rfSessions } = usePricechanger();

  const onUpdatePriceButtonClick = useCallback(() => {
    if (product?.id) {
      updateItems(product.id, (rfSessions?.sessions || []), () => {
        refreshSessions();
        onUpdate();
      });
    }
  }, [product, rfSessions, refreshSessions, updateItems, onUpdate]);

  return (
    <div className="flex flex-col gap-1">
      <p className="my-4">{isUpdating ? "Sessions are currently being updated. Please wait…" : "Are you sure want to update the sessions?"}</p>

      <hr className="mt-2" />
      <div className="flex justify-end gap-2 mt-5">
        <Button onClick={onCancel} className="bg-red-500">
          Cancel
        </Button>

        <Button onClick={onUpdatePriceButtonClick} loading={isUpdating} disabled={isUpdating}>
          Update
        </Button>
      </div>
    </div>
  );
};

// const ArrowCircularRightIcon = ()=>(
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 w-5">
//     <path strokeLinecap="round" strokeLinejoin="round" d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3" />
//   </svg>
// )

// const ArrowCircularLeftIcon = ()=>(
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 w-5">
//     <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
//   </svg>
// )

// const ArrowCircularRightIcon = ()=>(
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 w-5">
//     <path strokeLinecap="round" strokeLinejoin="round" d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3" />
//   </svg>
// )

// const ArrowCircularLeftIcon = ()=>(
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 w-5">
//     <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
//   </svg>
// )

export default Header;
