import { FC, useCallback, useEffect, useRef, useState } from "react";
import Label from "./Label";

interface Props {
  required?: boolean;
  defaultValue?: number;
  value?: number;
  onChange?: (val: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  hideLabel?: boolean;
  className?: string;
  disabled?: boolean;
  hideArrows?: boolean;
  defaultEmpty?: boolean;
}
const NumberInput: FC<Props> = ({
  defaultValue = 0,
  required = false,
  value: parentValue,
  label,
  hideLabel = false,
  className = "",
  onChange,
  disabled = false,
  hideArrows = false,
  defaultEmpty = false,
}) => {
  const isMounted = useRef(null);
  const [value, setValue] = useState<number | undefined>(undefined);
  const [error, setError] = useState("");

  const validate = useCallback(() => {
    if (required && value === undefined) {
      setError("This field is required.");
    } else {
      setError("");
    }
  }, [required, value]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue(+e.target.value);
      onChange && onChange(+e.target.value, e);
    },
    [onChange]
  );

  useEffect(() => {
    if (!isMounted.current) {
      if(defaultValue || !defaultEmpty) { 
        setValue(defaultValue);
      }
    }
  }, [defaultValue, defaultEmpty]);

  useEffect(() => {
    if (parentValue !== undefined) {
      setValue(parentValue);
    }
  }, [parentValue]);

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {!hideLabel && !!label && (
        <Label showRequiredSymbol={required}>{label}</Label>
      )}

      <input
        type="number"
        className={`flex-1 p-3 rounded-lg border border-solid border-[#C2C0C3] text-[#393B41] text-xs not-italic font-medium leading-[normal] font-[Montserrat] ${hideArrows ? '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' : ''}`}
        value={value}
        onChange={handleChange}
        onBlur={validate}
        disabled={disabled}
      />
      {error && (
        <p className="text-red-600 text-xs font-bold leading-[normal] font-[Montserrat]">
          {error}
        </p>
      )}
    </div>
  );
};

export default NumberInput;
